import axios from 'axios';

const apiProjects = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_PROJECTS_PROD
			: process.env.REACT_APP_PROJECTS_HOMOLOG,
});

export default apiProjects;
