import axios from 'axios';
import config from '../config';

const apiCupomFiscal = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_CUPOMFISCAL_PROD
			: process.env.REACT_APP_CUPOMFISCAL_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key':
			process.env.REACT_APP_PROD === 'true'
				? config.apikeyCencopay
				: 'ZZEBD8PeAC2SfHejwxCJl1KyPyNRPuWF3wLDKsVW',
	},
});

export default apiCupomFiscal;
