import React, { useState, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiAuth from '../../../services/apiAuth';

const initialState = {
	dtInicial: new Date(),
	dtFinal: new Date(),
	dsLoginAd: null,
	dsLoginAdAbm: null,
	idGroup: null
};

const ReportSisfContext = React.createContext({
	ReportSisf: initialState,
	onChangeReportAbm: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
	page: 1,
	setPage: () => console.log('Not implemented yet'),
}, []);

ReportSisfContext.displayName = "Contexto Relatório Relatório Sisf"

const validationSchema = yup.object({
	dtInicial: yup
		.string('Digite a data inicial')
		// .test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('Data é obrigatório'),
		dtInicial: yup
		.string('Digite a data final')
		// .test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('Data é obrigatório'),
});

export function ReportSisfProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [filter, setFilter] = useState()
	const [dataReal, setDataReal] = useState('');
	const [activeStep, setActiveStep] = useState(0);
	const [consultData, setConsultData] = useState([]);
	const [dataTransactions, setDataTransactions] = useState([]);
	const [accessGroupList, setAccessGroupList] = useState([])
	const [ReportLogsEvents, setReportLogsEvents] = useState(initialState);
	const [page, setPage] = useState(1);
    const [formValues, setFormValues] = useState(null);
	const formik = useFormik({
		initialValues: ReportLogsEvents,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
			setDataReal(data);
		},
	});

    const handleNext = useCallback(() => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}, []);

	const handleBack = useCallback(() => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		setFilter(undefined);
		formik.setFieldValue('dsLoginAd', '');
		formik.setFieldValue('idGroup', '');
	}, [formik]);

	const onChangeReportLogsEvents = useCallback((data) => {
		setReportLogsEvents((prevReportLogsEvents) => ({ ...prevReportLogsEvents, ...data }));
	}, []);

    const handleSubmit = useCallback(async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const payload = {
			dtini: moment(data.dtInicial).format('YYYY-MM-DD 00:00:00'),
			dtfin: moment(data.dtFinal).format('YYYY-MM-DD 23:59:59'),
			user_login_ad: data.dsLoginAd === undefined ? '' : data.dsLoginAd,
			user_login_ad_abm: data.dsLoginAdAbm === undefined ? '' : data.dsLoginAdAbm,
			id_group: data.idGroup === undefined ? '' : data.idGroup,
		};

		await apiAuth
			.post(`log/event/query`, payload)
			.then((res) => {
				onChangeLoading({ open: false });

				if (res.status === 200) {
					setConsultData(res.data);
					handleNext();
					resetForm();

					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Consulta realizada com sucesso! `,
					});
				} else {
					resetForm();

					onChangeNotify({
						open: true,
						class: 'attention',
						msg: `Não encontramos dados! `,
					});
				}
			})
			.catch(() => {
				onChangeLoading({ open: false });

				resetForm();

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	}, [onChangeLoading, onChangeNotify, handleNext]);

	const handleSelectFilter = useCallback((selected) => {
		setFilter(selected);
		formik.setFieldValue('dsLoginAd', '');
		formik.setFieldValue('idGroup', '');
	}, [formik]);

	const getAccessGroupList = useCallback(async () => {
		await apiAuth
			.get(`group/query`)
			.then((res) => {
				setAccessGroupList(res.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar os grupos de acesso.',
				});
			});
	}, [onChangeNotify]);

	const handleGetTransactions = async (data, page) => {
		const filters = data || formValues;

        if (!filters) return;
        setFormValues(filters);

		const payload = {
			dtini: filters.dtInicial ? moment(filters.dtInicial).format('YYYY-MM-DD 00:00:00') : moment(new Date()).format('YYYY-MM-DD 00:00:00'),
			dtfin: filters.dtFinal ? moment(filters.dtFinal).format('YYYY-MM-DD 23:59:59') : moment(new Date()).format('YYYY-MM-DD 23:59:59'),
			user_login_ad: filters.dsLoginAd || '',
			page,
		};
            onChangeLoading({ open: true });
        try {
            const res = await apiAuth.post(`log/transaction/query`, payload);
            onChangeLoading({ open: false });
            if (res.status === 200) {
                setDataTransactions(res.data);
                onChangeNotify({
                    open: true,
                    class: 'success',
                    msg: 'Transações consultadas com sucesso!',
                });
            } else {
                onChangeNotify({
                    open: true,
                    class: 'attention',
                    msg: 'Não encontramos dados para o período/usuário!',
                });
            }
        } catch (error) {
            onChangeLoading({ open: false });
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Erro ao consultar as transações.',
            });
        }
	};

	const handlePageChange = (newPage) => {
		setPage(newPage);
		handleGetTransactions(null, newPage);
	};

    return (
        <ReportSisfContext.Provider
            value={{
                formik,
                handleNext,
                handleBack,
                ReportLogsEvents,
                setReportLogsEvents,
                consultData,
                dataTransactions,
                handleGetTransactions,
                setConsultData,
                dataReal,
                setDataReal,
                activeStep,
                setActiveStep,
                filter,
                setFilter,
                accessGroupList,
                setAccessGroupList,
                getAccessGroupList,
                handleSelectFilter,
                onChangeReportLogsEvents,
				page,
				setPage: handlePageChange,
                formValues,
            }}
        >
            {children}
        </ReportSisfContext.Provider>
    )
}

export function useReportSisf() {
	const context = React.useContext(ReportSisfContext);

	if (!context)
		throw new Error('useReportSisf must be used within an AuthProvider');

	return context;
}
