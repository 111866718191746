import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useIdleTimer } from 'react-idle-timer';

import { StylesProvider } from '@material-ui/core/styles';
import { theme } from '../themes';
import Routes from '../routes/index';
import AppProvider from '../hooks';

import Notify from '../components/Notify';
import Loading from '../components/Loading';
import SessionExpirationModal from '../components/SessionExpirationModal';
import StoreModal from '../components/StoreModal';


function App() {
	const onPrompt = () => {
		// Fire a Modal Prompt
	};

	const onIdle = () => {
		// Close Modal Prompt
		// Do some idle action like log out your user
	};

	const onActive = (event) => {
		// Close Modal Prompt
		// Do some active action
	};

	const onAction = (event) => {
		// Do something when a user triggers a watched event
	};

	const {
		start,
		reset,
		activate,
		pause,
		resume,
		isIdle,
		isPrompted,
		isLeader,
		getTabId,
		getRemainingTime,
		getElapsedTime,
		getLastIdleTime,
		getLastActiveTime,
		getTotalIdleTime,
		getTotalActiveTime,
	} = useIdleTimer({
		onPrompt,
		onIdle,
		onActive,
		onAction,
		timeout: 1000 * 60 * 20,
		promptTimeout: 0,
		events: [
			'mousemove',
			'keydown',
			'wheel',
			'DOMMouseScroll',
			'mousewheel',
			'mousedown',
			'touchstart',
			'touchmove',
			'MSPointerDown',
			'MSPointerMove',
			'visibilitychange',
		],
		immediateEvents: [],
		debounce: 0,
		throttle: 0,
		eventsThrottle: 200,
		element: document,
		startOnMount: true,
		startManually: false,
		stopOnIdle: false,
		crossTab: false,
		name: 'idle-timer',
		syncTimers: 0,
		leaderElection: false,
	});



	return (
		<AppProvider>
			<CssBaseline />
			<StylesProvider>
				<ThemeProvider theme={theme()}>
					<Routes />
					<Notify />
					<Loading />
					<SessionExpirationModal />
					<StoreModal />
				</ThemeProvider>
			</StylesProvider>
		</AppProvider>
	);
}

export default App;
