import axios from 'axios';
import config from '../config';

const apiCencopay = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_CARTAO_BENEFICIO_PROD
			: process.env.REACT_APP_CARTAO_BENEFICIO_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		 'x-api-key':
		 	process.env.REACT_APP_PROD === 'true'
		 		? process.env.REACT_APP_API_KEY_CENCOPAY_BENEFITCARD
		 		: process.env.REACT_APP_API_KEY_CENCOPAY_BENEFITCARD,
	},
});

export default apiCencopay;
