import React from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useAuth } from '../../../hooks/auth';

import ValidEmail from '../../../utils/validEmail';

import apiArmazenamentoFaturaS3 from '../../../services/apiArmazenamentoFaturaS3';
import apiFaturaSisf from '../../../services/apiFaturaSisf';
import apiEnviaEmail from '../../../services/apiEnviaEmail';

const InvoiceContext = React.createContext({});

export function InvoiceProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [isLoading, setIsLoading] = React.useState(true);
	const [data, setData] = React.useState([]);
	const [cpf, setCpf] = React.useState('');
	const [initialDate, setInitialDate] = React.useState('');
	const [finalDate, setFinalDate] = React.useState('');
	const [disableEmail, setDisableEmail] = React.useState(true);
	const [sendingEmail, setSendingEmail] = React.useState(false);
	const [disableDownload, setDisableDownload] = React.useState(false);

	const [openModal, setOpenModal] = React.useState(false);
	const [email, setEmail] = React.useState('');
	const [modalData, setModalData] = React.useState();
	const [base64, setBase64] = React.useState('');

	const getData = async () => {
		if (cpf === '' || initialDate === '' || finalDate === '') {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Preencha todos os campos.',
			});
		} else {
			setIsLoading(true);
			onChangeLoading({
				open: true,
				msg: 'Consultando...',
			});

			await apiFaturaSisf
				.get(
					`/fatura/busca?nrCpf=${cpf
						.toString()
						.replace(
							/\.|-/gm,
							''
						)}&dtInicial=${initialDate}&dtFinal=${finalDate}`
				)
				.then((res) => {
					onChangeLoading({ open: false });
					setData(res.data);

					handleRecordLog(
						'CONSULTA',
						'CONSULTA',
						res?.data[0]?.nmCliente,
						res?.data[0]?.nrCpf
					);

					setIsLoading(false);
					if (res?.data?.length === 0) {
						onChangeNotify({
							open: true,
							class: 'error',
							msg: 'Nenhuma fatura foi encontrada.',
						});
					}
				})
				.catch((error) => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca, tente novamente!',
					});
				});
		}
	};

	const validarEmail = (emailParam) => {
		setDisableEmail(!ValidEmail(emailParam?.toLowerCase()));
	};

	const handleSendEmail = async () => {
		const objData = {
			nrContrato: '',
			dsEmail: email,
			dsNome: modalData?.nmCliente,
			nrCpf: modalData?.nrCpf,
			cdBandeira: Number(modalData?.cdNegocio),
			anexoBase64: base64?.base64_data,
			tpEnvio: 'FATURA',
			dsProduto: 'CARTAO',
		};

		setSendingEmail(true);
		await apiEnviaEmail
			.post(`enviar-email-anexo-dtm`, objData)
			.then((res) => {
				handleRecordLog(
					'CONSULTA',
					'ENVIO EMAIL',
					modalData?.nmCliente,
					modalData?.nrCpf,
					modalData?.nmModalidade,
					modalData?.cdLogo,
					modalData?.dtVencimento,
					email
				);

				handleCloseModal();
				setSendingEmail(false);
				onChangeLoading({
					open: false,
				});
				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Fatura enviada com sucesso para: ${email?.toUpperCase()}`,
				});
			})
			.catch((error) => {
				setSendingEmail(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleGetBase64 = async (params) => {
		// console.log(params);
		const { nmConta } = params;

		const acount = nmConta === null ? '0000' : nmConta;
		// console.log(acount);
		setDisableDownload(true);

		const day = params.dtVencimento.slice(0, 2);
		const month = params.dtVencimento.slice(3, 5);
		const year = params.dtVencimento.slice(6, 10);

		await apiArmazenamentoFaturaS3
			.get(
				`/faturas/${cpf
					.toString()
					.replace(/\.|-/gm, '')}/${year}/${month}/${day}/${acount}`
			)
			.then((res) => {
				const base64Data = `data:application/pdf;base64,${res?.data?.base64_data}`;
				const link = document.createElement('a');
				link.href = base64Data;
				link.download = params?.nmPdf;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				handleRecordLog(
					'CONSULTA',
					'DOWNLOAD',
					params?.nmCliente,
					params?.nrCpf,
					params?.nmModalidade,
					params?.cdLogo,
					params?.dtVencimento
				);

				setDisableDownload(false);
			})
			.catch((error) => {
				setDisableDownload(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleOpenModal = async (params) => {
		setSendingEmail(false);
		console.log(params);
		const { nmConta } = params;

		const acount = nmConta === null ? '0000' : nmConta;
		console.log(acount);

		const day = params.dtVencimento.slice(0, 2);
		const month = params.dtVencimento.slice(3, 5);
		const year = params.dtVencimento.slice(6, 10);

		await apiArmazenamentoFaturaS3
			.get(
				`/faturas/${cpf
					.toString()
					.replace(/\.|-/gm, '')}/${year}/${month}/${day}/${acount}`
			)
			.then((res) => {
				onChangeLoading({ open: false });
				setIsLoading(false);
				setBase64(res.data);
				setModalData(params);
				setEmail('');
				setDisableEmail(true);
				setOpenModal(true);
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca do arquivo, tente novamente!',
				});
			});
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setEmail('');
	};

	const handleRecordLog = async (
		screen = '',
		type = '',
		customerName = '',
		cpf = '',
		modality = '',
		logo = '',
		dueDate = '',
		destinationEmail = ''
	) => {
		const payload = {
			loginAd: user.login_ad,
			matriculaAd: user.matricula,
			nmColaborador: user.name,
			dsTela: screen,
			dsAcao: type,
			nmCliente: customerName,
			dsModalidadeFatura: modality,
			dsLogo: logo,
			dtVencimento: dueDate,
			dsEmailDestino: destinationEmail,
			nrCpfCliente: cpf,
		};

		await apiFaturaSisf
			.post(`/log/insert`, payload)
			.then((res) => {})
			.catch((error) => {});
	};

	return (
		<InvoiceContext.Provider
			value={{
				isLoading,
				cpf,
				setCpf,
				data,
				setData,
				getData,
				handleSendEmail,
				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,
				openModal,
				setOpenModal,
				handleCloseModal,
				email,
				setEmail,
				handleOpenModal,
				modalData,
				setModalData,
				disableEmail,
				validarEmail,
				sendingEmail,
				handleGetBase64,
				base64,
				disableDownload,
				handleRecordLog,
			}}
		>
			{children}
		</InvoiceContext.Provider>
	);
}

export function useInvoice() {
	const context = React.useContext(InvoiceContext);

	if (!context)
		throw new Error('useInvoice must be used within an AuthProvider');

	return context;
}
