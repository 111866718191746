import React from 'react';
import Papa from 'papaparse';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useAuth } from '../../../hooks/auth';

import apiRhSgi from '../../../services/apiRhSgi';
import apiAuth from '../../../services/apiAuth';

const RhSgiContext = React.createContext({});
RhSgiContext.displayName = 'Contexto de RH - SGI';

export function RhSgiProvider({ children }) {
	const history = useHistory();
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [csvData, setCsvData] = React.useState([]);
	const [fileName, setFileName] = React.useState('');
	const [operationType, setOperationType] = React.useState('I');
	const [showDataTable, setShowDataTable] = React.useState(false);
	const [hasInvalidData, setHasInvalidData] = React.useState(false);
	const [openModal, setOpenModal] = React.useState(false);

	const [sgiData, setSgiData] = React.useState({
		cpf: '',
		locality: '',
		registrationNumber: '',
		employeeName: '',
		admissionDate: '',
		employeeEmail: '',
		workPhone: '',
		rgNumber: '',
		idIssuanceDate: '',
		idIssuingBody: '',
		birthDate: '',
		personalPhoneNumber: '',
		fatherName: '',
		motherName: '',
		dismissalDate: '',
		dismissalReason: '',
		removalStartDate: '',
		removalEndDate: '',
		removalReason: '',
		vocationStartDate: '',
		vocationEndDate: '',
	});

	const handleCleanData = () => {
		setCsvData([]);
		setFileName('');
		setSgiData({
			cpf: '',
			locality: '',
			registrationNumber: '',
			employeeName: '',
			admissionDate: '',
			employeeEmail: '',
			workPhone: '',
			rgNumber: '',
			idIssuanceDate: '',
			idIssuingBody: '',
			birthDate: '',
			personalPhoneNumber: '',
			fatherName: '',
			motherName: '',
			dismissalDate: '',
			dismissalReason: '',
			removalStartDate: '',
			removalEndDate: '',
			removalReason: '',
			vocationStartDate: '',
			vocationEndDate: '',
		});
	};

	const handleChangeSgiData = (field, e) => {
		setSgiData({
			...sgiData,
			[field]: e.target.value,
		});
	};

	const handleDocument = (event) => {
		console.log('aqui');
		setHasInvalidData(false);
		setCsvData([]);
		setFileName('');

		Papa.parse(event.target.files[0], {
			header: true,
			dynamicTyping: true,
			skipEmptyLines: 'greedy', // ignora linhas vazias ou apenas com espaços após conversão
			complete: (results) => {
				const csvData = results.data;
				console.log(csvData);
				setCsvData(csvData);
				setFileName(event.target.files[0].name);
			},
		});
	};

	const handleChangeOperationType = (type) => {
		setOperationType(type);
		handleCleanData();
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		handleCleanData();
		setOperationType('I');
	};

	const handleInsertCsv = async () => {
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Enviando...',
		});

		const payload = {
			arquivo: fileName,
			cd_operacao: operationType,
			dados: csvData,
		};

		await apiRhSgi
			.post(`/insert-file`, payload)
			.then((res) => {
				if (res.status === 200) {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'success',
						msg: 'Enviado com sucesso!',
					});
					handleCleanData();
					setOperationType('I');
				}
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleInsertSeparate = async () => {
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Enviando...',
		});

		const payload = {
			arquivo: 'Cadastro Avulso',
			cd_operacao: operationType,
			dados: [
				{
					cpf: sgiData?.cpf.replace(/\D/g, ''),
					data_inicio_ferias: sgiData?.vocationStartDate
						? moment(sgiData?.vocationStartDate)?.format('DD/MM/YYYY')
						: '',
					data_fim_ferias: sgiData?.vocationEndDate
						? moment(sgiData?.vocationEndDate)?.format('DD/MM/YYYY')
						: '',
					data_desligamento: sgiData?.dismissalDate
						? moment(sgiData?.dismissalDate)?.format('DD/MM/YYYY')
						: '',
					motivo_desligamento: sgiData?.dismissalReason,
					data_inicio_afastamento: sgiData?.removalStartDate
						? moment(sgiData?.removalStartDate)?.format('DD/MM/YYYY')
						: '',
					data_fim_afastamento: sgiData?.removalEndDate
						? moment(sgiData?.removalEndDate)?.format('DD/MM/YYYY')
						: '',
					motivo_afastamento: sgiData?.removalReason,
					localidade: sgiData?.locality,
					matricula: sgiData?.registrationNumber,
					nome_funcionario: sgiData?.employeeName,
					data_admissao: sgiData?.admissionDate
						? moment(sgiData?.admissionDate)?.format('DD/MM/YYYY')
						: '',
					email: sgiData?.employeeEmail,
					telefone_trabalho: sgiData?.workPhone,
					numero_rg: sgiData?.rgNumber,
					data_emissao_rg: sgiData?.idIssuanceDate
						? moment(sgiData?.idIssuanceDate)?.format('DD/MM/YYYY')
						: '',
					orgao_emissor_rg: sgiData?.idIssuingBody,
					data_nascimento: sgiData?.birthDate
						? moment(sgiData?.birthDate)?.format('DD/MM/YYYY')
						: '',
					numero_celular: sgiData?.personalPhoneNumber,
					nome_pai: sgiData?.fatherName,
					nome_mae: sgiData?.motherName,
				},
			],
		};

		await apiRhSgi
			.post(`/insert-file`, payload)
			.then((res) => {
				if (res.status === 200) {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'success',
						msg: 'Enviado com sucesso!',
					});
					handleCleanData();
				}
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	// const handleInsertLog = async () => {
	// 	onChangeLoading({
	// 		open: true,
	// 		msg: 'Salvando alterações...',
	// 	});

	// 	const data = {
	// 		login_ad: 'petpsantos',
	// 		function_id: 21,
	// 		before: "['nome':'Jose de Alcantera']",
	// 		later: '',
	// 		type: 'system',
	// 		abm: 'delete',
	// 		app_id: '2c0ef621-0bf2-11ec-9f17-70cf49e3b4b3',
	// 	};

	// 	await apiAuth
	// 		.post('/log/event/insert', data, config)
	// 		.then((res) => {
	// 			onChangeLoading({ open: false });

	// 			onChangeNotify({
	// 				open: true,
	// 				class: 'success',
	// 				msg: `Cadastrado editado com sucesso! `,
	// 			});
	// 		})
	// 		.catch((error) => {
	// 			onChangeLoading({ open: false });

	// 			onChangeNotify({
	// 				open: true,
	// 				class: 'error',
	// 				msg: `Aconteceu algum erro!`,
	// 			});
	// 		});
	// };

	return (
		<RhSgiContext.Provider
			value={{
				csvData,
				fileName,
				handleDocument,
				operationType,
				setOperationType,
				showDataTable,
				setShowDataTable,
				hasInvalidData,
				setHasInvalidData,
				handleChangeOperationType,
				sgiData,
				handleChangeSgiData,
				openModal,
				handleOpenModal,
				handleCloseModal,
				handleInsertCsv,
				handleInsertSeparate,
			}}
		>
			{children}
		</RhSgiContext.Provider>
	);
}

export function useRhSgi() {
	const context = React.useContext(RhSgiContext);

	if (!context) throw new Error('useRhSgi must be used within an AuthProvider');

	return context;
}
