import React from 'react';
import {
	makeStyles,
	Fade,
	Button,
	ClickAwayListener,
	Paper,
	Popper,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Grid,
} from '@material-ui/core';

import LockOpenTwoTone from '@material-ui/icons/LockOpenTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import MeetingRoomTwoToneIcon from '@material-ui/icons/MeetingRoomTwoTone';

import Modal from '../../../../components/Modal';
import { useAuth } from '../../../../hooks/auth';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: '350px',
		minWidth: '250px',
		backgroundColor: theme.palette.background.paper,
		paddingBottom: 0,
		borderRadius: '10px',
	},
	subHeader: {
		backgroundColor: theme.palette.grey.A400,
		color: theme.palette.common.white,
		padding: '5px 15px',
	},
	menuIcon: {
		fontSize: '1.5rem',
	},
	menuButton: {
		[theme.breakpoints.down('sm')]: {
			minWidth: '50px',
		},
		[theme.breakpoints.down('xs')]: {
			minWidth: '35px',
		},
	},
	name: {
		paddingRight: '10px',
	},
}));

function ProfileSection() {
	const classes = useStyles();
	const { user, signOut } = useAuth();

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const [openModal, setOpenModal] = React.useState(false);
	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	return (
		<>
			<Button
				className={classes.menuButton}
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				color="inherit"
			>
				<Typography variant="body2" className={classes.name}>
					{user.name}
				</Typography>
				<AccountCircleTwoToneIcon className={classes.menuIcon} />
			</Button>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: {
						offset: {
							enable: true,
							offset: '0px, 10px',
						},
						preventOverflow: {
							padding: 0,
						},
					},
				}}
			>
				{({ TransitionProps, placement }) => (
					<Fade {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<List component="nav" className={classes.root}>
									<ListItem button onClick={handleOpenModal}>
										<ListItemIcon>
											<LockOpenTwoTone />
										</ListItemIcon>
										<ListItemText primary="v 2.0.6_p" />
									</ListItem>
									<ListItem button onClick={() => signOut()}>
										<ListItemIcon>
											<MeetingRoomTwoToneIcon />
										</ListItemIcon>
										<ListItemText primary="Sair" />
									</ListItem>
								</List>
							</ClickAwayListener>
						</Paper>
					</Fade>
				)}
			</Popper>
			<Modal title="Changelog" isOpen={openModal} onClose={handleCloseModal}>
				<Grid container spacing={2}>
					<Grid item>
						<Typography variant="subtitle1">
							<b> - v 1.5.0_p - 13-07-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.9_p - 06-07-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.8_p - 03-07-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.7_p - 03-07-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.6_h - 03-07-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.5_h - 03-07-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.4_h - 03-07-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.3_h - 29-06-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.2_h - 27-06-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.1_h - 22-06-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.4.0_h - 21-06-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.3.0_h - 12-05-2022</b>
							<ul>
								<li>Várias correções e melhorias;</li>
							</ul>
							<b> - v 1.2.0_h - 27-04-2022</b>
							<ul>
								<li>
									- Produtos CDC * Exibição dos produtos cadastrados * Filtro de
									produtos por bandeira, regional, loja e código do produto *
									Alteração do status (ativado/desativado) * Alteração da data
									de vigência * Alteração de bandeira/regional/loja à qual o
									produto está vinculado * Exclusão de produto * Modal com
									exibição dos detalhes do produto
								</li>
							</ul>
							<b> - v 1.1.36_h - 22-04-2020</b>
							<ul>
								<li>Correcao no identify;</li>
								<li>Ajuste dos campos e botões da tela de consulta;</li>
								<li>Ajuste dos campos e botões da tela de resultado;</li>
								<li>Criação da tela de Cadastro;</li>
								<li>
									Revisão e refatoração de códigos das telas de consulta e
									resultado;
								</li>
								<li>
									implementação do formik das telas de consulta e cadastro;
								</li>
								<li>Revisão do provider;</li>
								<li>Validação de campos ;</li>
							</ul>
							<b> - v 1.1.35_h - 22-04-2020</b>
							<ul>
								<li>Melhorias no EP e CDC;</li>
							</ul>
							<b> - v 1.1.34_h - 22-04-2020</b>
							<ul>
								<li>Melhorias no EP e CDC;</li>
							</ul>
							<b> - v 1.1.33_h - 22-04-2022</b>
							<ul>
								<li>
									Correção na consulta do CEP no Cadastro de Proposta EP e CDC;
								</li>
								<li>
									Correção na máscara do campo CEP no Cadastro de Proposta EP e
									CDC;
								</li>
								<li>
									Caso o produto não tenha serviço não exibir o botão
									"selecionar serviço";
								</li>
								<li>
									Ao enviar proposta CDC tambem esta sendo enviado o pedido do
									produto selecioando;
								</li>
								<li>Correção na menu do produto CDC;</li>
								<li>
									Inserido checkbox com aceite do envio da proposta no CDC e EP;
								</li>
								<li>Correção no botão de nova proposta do CDC e EP;</li>
								<li>
									Correção na Data de Emissão RG (deve ser obrigatório), Numero
									do doc CNH, cód Segurança;
								</li>
							</ul>
							<b> - v 1.1.29_h - 08-04-2020</b>
							<ul>
								<li>
									Criação da função checkPermission no provider simulateProposal
									para verificar o acesso ao cadastro de proposta EP, CDC e
									CARTÃO;
								</li>
							</ul>
							<b> - v 1.1.28_h - 06-04-2022</b>
							<ul>
								<li>Correção no localstorage de simular proposta;</li>
							</ul>
							<b> - v 1.1.27_h - 04-04-2022</b>
							<ul>
								<li>Correção no numero de telefone;</li>
							</ul>
							<b> - v 1.1.25_h - 04-04-2022</b>
							<ul>
								<li>Correção nos campos de data;</li>
								<li>Adição do link de segurança no Breadcrumb; </li>
								<li>
									Migração do método closeMassRegistration para dentro do
									provider;
								</li>
								<li>
									Correção no RadioGroup de seleção de brand, regional e store;
								</li>
								<li>Correção da variável codigoPlu, de string para int;</li>
								<li>
									Correção Preenchido Email na P1, porém não replicou para a P2B
									(etapa de dados pessoais)
								</li>
								<li>
									Correção Tela Resumo - Não está preenchendo o campo "nome
									completo" no resumo da proposta
								</li>
								<li>Correção Tela Pop up, não deixar disponível com (-)</li>
								<li>
									Ajuste no Menu - Escolha do Produto Valor aprovado: R$ xxx,xx
									Valor do produto: R$ xxx,xx
								</li>
								<li>
									Importante que a janela "selecionar serviço" não apareça caso
									o produto não tenha serviço disponível
								</li>
								<li>
									Serviços de Furto e Roubo, Danos Acidentais e combo não estão
									disponíveis para serem selecionados
								</li>
								<li>Correção da mensagem de datas Futuras;</li>
								<li>Posicionamento correto da imagem da CNH na tela</li>
								<li>Data de emissão cod. CNH e Nº CNH obrigatórios </li>
								<li>Deixar apenas CC em dados bancários</li>
								<li>Ordenação das praças do banco</li>
							</ul>
						</Typography>
					</Grid>
				</Grid>
			</Modal>
		</>
	);
}

export default ProfileSection;
