import axios from 'axios';
import config from '../config';

const apiRhSgi = axios.create({
	baseURL:
		// 'http://localhost:3000',
		process.env.REACT_APP_PROD === 'false'
			? process.env.REACT_APP_RH_SGI_PROD
			: process.env.REACT_APP_RH_SGI_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
	},
});

export default apiRhSgi;
