import { useEffect, useRef } from "react";
import apiAuth from "../services/apiAuth";

const useTransactionLogger = (user) => {
  const sessionId = useRef(`session-${Math.random().toString(36).substr(2, 9)}`);
  const previousUrl = useRef(document.referrer);
  const pageLoadTime = useRef(Date.now());

  useEffect(() => {
    if (process.env.REACT_APP_PROD !== "true" || !user) return;

    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error("Erro ao buscar IP:", error);
        return "IP-desconhecido";
      }
    };

    const getBrowserInfo = () => {
      return {
        browser: navigator.userAgent,
        os: navigator.platform,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        isMobile: /Mobi|Android/i.test(navigator.userAgent),
      };
    };

    const createLog = async (event, eventType) => {
      const targetElement = event.target;
      const timestamp = new Date().toLocaleString();
      const ipAddress = await fetchIpAddress();
      const browserInfo = getBrowserInfo();
      const duration = Date.now() - pageLoadTime.current;

      const logData = {
        user,
        sessionId: sessionId.current,
        timestamp,
        ipAddress,
        browser: browserInfo.browser,
        os: browserInfo.os,
        screenResolution: browserInfo.screenResolution,
        isMobile: browserInfo.isMobile,
        url: window.location.href,
        previousUrl: previousUrl.current || "sem-url-anterior",
        eventType,
        element: targetElement.tagName,
        id: targetElement.id || "sem-id",
        className: targetElement.className || "sem-classe",
        value: eventType === "input"
          ? targetElement.value || "sem-valor"
          : targetElement.textContent?.trim().slice(0, 30) || "sem-texto",
        errorMessage: "",
        duration: `${duration} ms`,
      };

      try {
        await apiAuth.post("/log/transaction/insert", logData);
      } catch (error) {
        console.error("Erro ao enviar log:", error);
      }
    };

    const handleClick = (event) => createLog(event, "click");
    const handleInput = (event) => createLog(event, "input");

    document.addEventListener("click", handleClick);
    document.addEventListener("input", handleInput);

    () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("input", handleInput);
    };
  }, [user]);
};

export default useTransactionLogger;
