import Papa from 'papaparse';
import React, { useContext, useEffect, useState, useCallback } from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import apiCencopay from '../../../services/apiCencopay-gwUsersS3';
import { useAuth } from '../../../hooks/auth';

const AccountBlockFraudContext = React.createContext({});
AccountBlockFraudContext.displayName = 'Contexto Fraude de Consulta Conta Bloqueada - Cencopay';

const cleanCpf = (cpf) => cpf.replace(/\.|-/g, '');

export function AccountBlockingFraudProvider({ children }) {
    const { onChangeNotify } = useNotify();
    const { onChangeLoading } = useLoading();

    const [cpf, setCpf] = useState('');
    const [data, setData] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModalMassivo, setOpenModalMassivo] = useState(false);
    const [blockDeviceCpf, setBlockDeviceCpf] = useState([]);
    const [hasNegativeValue, setHasNegativeValue] = useState(false);
    const [fileName, setFileName] = useState('');
    const [cpfList, setCpfList] = useState([]);

    const [userDetails, setUserDetails] = useState(null);
    const [blockOptions, setBlockOptions] = useState([]);
    const [selectedBlock, setSelectedBlock] = useState('');
    const [blockDetails, setBlockDetails] = useState([]);


    const updateUserDetails = async (cpf) => {
        try {
            const response = await apiCencopay.get(`/users/sisf/${cpf}`);
            if (response.status === 200) {
                setUserDetails(response.data);
                console.log('userDetails atualizado:', response.data);
            }
        } catch (error) {
            console.error('Erro ao atualizar userDetails:', error.message);
        }
    };

    // Função para buscar opções iniciais de bloqueio
    const getAllBlocks = async () => {
        try {
            const response = await apiCencopay.get(`/Blocks`);
            const blocks = response.data || [];
            setBlockOptions(blocks);
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                message: "Erro ao buscar bloqueios."
            });
            throw error;
        }
    }

    // Função para buscar detalhes do bloqueio por ID
    const fetchBlockDetails = async (blockId) => {
        try {
            const response = await apiCencopay.get(`/${blockId}`);
            const data = await response.json();
            setBlockDetails(data);
        } catch (error) {
            console.error('Erro ao buscar detalhes do bloqueio:', error);
        }
    };

    // Função para buscar os dados do usuário
    const getData = useCallback(async () => {
        if (!cpf && cpf === '') {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Por favor, preencha o CPF para realizar a consulta.',
            });
            return;
        }

        onChangeLoading({
            open: true,
            msg: 'Consultando...',
        });

        const cleanedCpf = cleanCpf(cpf);

        try {
            const [userResponse, blocksResponse] = await Promise.all([
                apiCencopay.get(`/users/sisf/${cleanedCpf}`),
                apiCencopay.get(`/users/blocks/${cleanedCpf}`)
            ]);

            if (userResponse.status === 200) {
                setData(Array.isArray(userResponse.data) ? userResponse.data : [userResponse.data]);
                onChangeNotify({
                    open: true,
                    class: 'success',
                    msg: `Consulta realizada com sucesso!`,
                });
            } else {
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Nenhum dado encontrado para o CPF fornecido.',
                });
            }

            if (blocksResponse.status === 200) {
                setBlocks(Array.isArray(blocksResponse.data) ? blocksResponse.data : [blocksResponse.data]);
            }

        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro na busca, tente novamente!',
            });
        } finally {
            setLoading(false);
            onChangeLoading({ open: false });
        }
    }, [cpf, onChangeLoading, onChangeNotify]);

    const refreshData = useCallback(async () => {
        if (!cpf && cpf === '') {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Por favor, preencha o CPF para realizar a consulta.',
            });
            return;
        }

        
        const cleanedCpf = cleanCpf(cpf);

        try {
            const [userResponse, blocksResponse] = await Promise.all([
                apiCencopay.get(`/users/sisf/${cleanedCpf}`),
                apiCencopay.get(`/users/blocks/${cleanedCpf}`)
            ]);

            if (userResponse.status === 200) {
                setData(Array.isArray(userResponse.data) ? userResponse.data : [userResponse.data]);
                onChangeNotify({
                    open: true,
                    class: 'success',
                    msg: `Bloqueios atualizados com Sucesso`,
                });
            } else {
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Nenhum dado encontrado para o CPF fornecido.',
                });
            }

            if (blocksResponse.status === 200) {
                setBlocks(Array.isArray(blocksResponse.data) ? blocksResponse.data : [blocksResponse.data]);
            }

        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro, tente novamente!',
            });
        } finally {
            setLoading(false);
            onChangeLoading({ open: false });
        }
    }, [cpf, onChangeLoading, onChangeNotify]);

    // Função para salvar o bloqueio no banco de dados
    const saveBlockToDatabase = useCallback(async (blockId) => {
        try {
            if (!blockId || typeof blockId !== 'string') {
                throw new Error('ID de bloqueio inválido');
            }

            const cleanedCpf = cpf?.replace(/\D/g, '');

            if (!cleanedCpf) {
                throw new Error('CPF do usuário não está disponível para atualização');
            }

            const payload = {
                block_mambo: blockId,
                block_beneficio: blockId
            };

           await apiCencopay.put(`/users/block-cpf/${cleanedCpf}`, payload);
            
            refreshData();
            
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                message: "Erro ao atualizar bloqueio!"
            });
            console.error('Erro ao salvar bloqueio:', error.message);
            if (error.response) {
                console.error('Detalhes do erro:', error.response.data);
            }
        }
    },[cpf, onChangeNotify]);

    const handleInputChange = useCallback((e) => {
        const { value } = e.target;
        setCpf(value);

        if (value === '') {
            setData([]);
            setBlockDeviceCpf([]);
        }
    }, []);

    const handleOpenModalMassivo = useCallback(() => {
        setOpenModalMassivo(true);
    }, []);

    const handleCloseModalMassivo = useCallback(() => {
        setOpenModalMassivo(false);
        setHasNegativeValue(false);
        setFileName('');
    }, []);

    // Função para realizar o bloqueio massivo
    const handleInsertBlocksMassivo = useCallback(async () => {
        if (cpfList.length === 0) {
            onChangeNotify({
                open: true,
                class: 'warning',
                msg: 'Nenhum dado disponível para realizar o bloqueio massivo.',
            });
            return;
        }
    
        // Formatar os CPFs e filtrar bloqueios válidos
        const payloads = cpfList.map(({ cpf, block_mambo, block_beneficio }) => ({
            cpf: cpf.toString().padStart(11, '0'),
            block_mambo: block_mambo && block_mambo.trim().toUpperCase() !== 'I' && block_mambo.trim().toUpperCase() !== 'P' 
                ? block_mambo.trim() 
                : "I",
            block_beneficio: block_beneficio && block_beneficio.trim().toUpperCase() !== 'I' && block_beneficio.trim().toUpperCase() !== 'P' 
                ? block_beneficio.trim() 
                : "I",
        })).filter(payload => payload.block_mambo || payload.block_beneficio); // Remove os objetos sem bloqueios válidos
    
        if (payloads.length === 0) {
            onChangeNotify({
                open: true,
                class: 'warning',
                msg: 'Nenhum bloqueio válido foi encontrado no arquivo CSV.',
            });
            return;
        }
    
        let isMounted = true;
    
        try {
            onChangeLoading({
                open: true,
                msg: 'Carregando...',
            });
    
            console.log('Enviando bloqueio massivo:', payloads);
    
            // Enviar o array completo para a API
            const response = await apiCencopay.put(`/users/block-massive`, payloads);
    
            if (response.status === 200) {
                if (isMounted) {
                    onChangeNotify({
                        open: true,
                        class: 'success',
                        msg: 'Bloqueio massivo realizado com sucesso!',
                    });
                }
            } else {
                console.error('Erro na resposta da API:', response);
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Erro ao realizar o bloqueio massivo. Tente novamente!',
                });
            }
        } catch (error) {
            console.error('Erro ao tentar realizar o bloqueio massivo:', error);
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu um erro ao tentar realizar o bloqueio massivo. Tente novamente!',
            });
        } finally {
            if (isMounted) {
                onChangeLoading({ open: false });
                handleCloseModalMassivo();
            }
        }
    
        return () => {
            isMounted = false;
        };
    }, [onChangeLoading, onChangeNotify, handleCloseModalMassivo, cpfList]);
    

    const sendBlockRequest = async (payload) => {
        const { cpf, block_mambo, block_beneficio } = payload;
        const cpfFormated = cpf.toString().padStart(11, '0');
        const requestBody = {
            cpf: cpfFormated,
            block_mambo,
            block_beneficio
        };

        console.log('Iniciando envio de bloqueio para CPF:', cpf);
        console.log('Valores de bloqueio:', { block_mambo, block_beneficio });

        // Ajustando para permitir valores válidos e evitar vazios ou "N"
        if (block_mambo && block_mambo.trim().toUpperCase() !== 'N') {
            requestBody.block_mambo = block_mambo.trim();
        }

        if (block_beneficio && block_beneficio.trim().toUpperCase() !== 'N') {
            requestBody.block_beneficio = block_beneficio.trim();
        }

        if (Object.keys(requestBody).length === 0) {
            console.log('Nenhum bloqueio definido, não será enviada a requisição.');
            return;
        }

        console.log('payload', requestBody)

        try {
            
            const response = await apiCencopay.put(`/users/block-massive`, requestBody);

            
            if (response.status !== 200) {
                console.error(`Erro ao aplicar bloqueio para o CPF ${cpf}:`, response);
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: `Erro ao aplicar bloqueio para o CPF ${cpf}: ${response.statusText}`,
                });
            }
        } catch (error) {
            console.error(`Erro ao aplicar bloqueio para o CPF ${cpf}`, error);
            onChangeNotify({
                open: true,
                class: 'error',
                msg: `Erro ao aplicar bloqueio para o CPF ${cpf}!`,
            });
        }
    };

    const handleDocument = useCallback((event) => {
        setFileName('');
        setHasNegativeValue(false);

        Papa.parse(event.target.files[0], {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: 'greedy',
            complete: (results) => {
                const parsedData = results.data;
                const formattedData = parsedData.map(row => ({
                    cpf: row.cpf ? String(row.cpf).trim() : undefined,
                    block_mambo: row.block_mambo ? String(row.block_mambo).trim() : undefined,
                    block_beneficio: row.block_beneficio ? String(row.block_beneficio).trim() : undefined,
                }));
                setCpfList(formattedData);
                setFileName(event.target.files[0].name);
            },
            error: (error) => {
                console.error('Erro ao fazer parsing do arquivo CSV:', error);
            }
        });
    }, []);

    useEffect(() => {
        if (cpf === '') {
            setData([]);
            setBlockDeviceCpf([]);
        }
    }, [cpf, setData, setBlockDeviceCpf]);

    useEffect(() => {
        const savedBlock = localStorage.getItem('@SF:SelectedBlock');
        if (savedBlock) {
            setSelectedBlock(savedBlock);
        }
    }, []);

    useEffect(() => {
        if (selectedBlock) {
            localStorage.setItem('@SF:SelectedBlock', selectedBlock);
        }
    }, [selectedBlock]);

    const fetchUsersData = async (cpf) => {
        try {
            const response = await apiCencopay.get(`/users/sisf/${cpf}`);

            if (response.status === 200) {
                return response.data;
            }
            console.warn('Resposta da API não foi 200. Dados do usuário não encontrados.');
            return [];
        } catch (error) {
            console.error('Erro ao buscar os dados do usuário:', error);
            return [];
        }
    };

    const maskCpf = (cpf) => {
        if (!cpf) return '';
        const unmaskedCpf = cpf.replace(/\D/g, '');
        if (unmaskedCpf.length !== 11) return cpf;
        return unmaskedCpf.replace(/(\d{3})\d{6}(\d{2})/, '$1.***.***-$2');
    };

    const maskCellphone = (cellphone) => {
        if (!cellphone) return '';
        const unmaskedCellphone = cellphone.replace(/\D/g, '');

        if (unmaskedCellphone.length === 11) {
            return unmaskedCellphone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } if (unmaskedCellphone.length === 10) {
            return unmaskedCellphone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        }
        if (unmaskedCellphone.length === 13) {
            return unmaskedCellphone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
        }

        return cellphone;
    };

    function WalletBalanceProvider(balance) {
        const formattedBalance = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(balance);
        const displayBalance = balance > 0 ? formattedBalance : 'R$ 0,00';
        return displayBalance;
    }

    const handleSaveBlock = useCallback(async (blockId, walletSource) => {
        try {
            if (!blockId || typeof blockId !== 'string') {
                throw new Error('ID de bloqueio inválido');
            }

            const cleanedCpf = cpf?.replace(/\D/g, '');

            if (!cleanedCpf) {
                throw new Error('CPF do usuário não está disponível para atualização');
            }

            const payload = walletSource === 'mambo'
                ? { block_mambo: blockId, block_beneficio: "" }
                : { block_mambo: "", block_beneficio: blockId };


            const response = await apiCencopay.put(`/users/block-cpf/${cleanedCpf}`, payload);


            if (response.status === 200) {
                onChangeNotify({
                    open: true,
                    class: 'success',
                    message: "Bloqueio salvo no banco com sucesso!"
                });
                getData();
            } else {
                throw new Error(`Erro ao atualizar bloqueio: ${response.statusText}`);
            }
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                message: "Erro ao salvar bloqueio!"
            });
            if (error.response) {
                console.error('Detalhes do erro:', error.response.data);
            }
        }
    }, [cpf, onChangeNotify]);

    const resetForm = useCallback(() => {
        setCpf('');
        setData([]);
    }, []);

    return (
        <AccountBlockFraudContext.Provider
            value={{
                cpf,
                data,
                setCpf,
                getData,
                refreshData,
                maskCpf,
                maskCellphone,
                WalletBalanceProvider,
                handleInputChange,
                fileName,
                loading,
                hasNegativeValue,
                handleDocument,
                openModalMassivo,
                handleOpenModalMassivo,
                handleCloseModalMassivo,
                handleInsertBlocksMassivo,
                fetchUsersData,
                getAllBlocks,
                resetForm,

                // Bloqueio de conta
                userDetails,
                blockOptions,
                selectedBlock,
                setSelectedBlock,
                blockDetails,
                handleSelectBlock: setSelectedBlock,
                saveBlockToDatabase,
                updateUserDetails,
                handleSaveBlock,
            }}
        >
            {children}
        </AccountBlockFraudContext.Provider>
    );
}

export function useAccountBlockingFraud() {
    const context = useContext(AccountBlockFraudContext);

    if (!context)
        throw new Error('AccountBlockingFraud must be used within an AuthProvider');

    return context;
}
