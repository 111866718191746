import axios from 'axios';
import config from '../config';

const apiCampanhaCencopay = axios.create({
	baseURL:
		// 'https://9l2llkkn-7233.brs.devtunnels.ms/api/',
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_API_CAMPANHA_CENCOPAY_PROD
			: process.env.REACT_APP_API_CAMPANHA_CENCOPAY_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key':
			process.env.REACT_APP_PROD === 'true'
				? 'ZZEBD8PeAC2SfHejwxCJl1KyPyNRPuWF3wLDKsVW'
				: 'lLbguFz1KS7csPGvpA9RY6esUjffYyC11rDDYQJG',
	},
});

export default apiCampanhaCencopay;
