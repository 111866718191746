import React from 'react';
import {
	Avatar,
} from '@material-ui/core';
import { deepOrange, lightGreen } from '@material-ui/core/colors'

import { useSessionExpiration } from '../../../../hooks/sessionExpiration';

function SessionExpirationSection() {
	const { minutes, seconds } = useSessionExpiration();

	return (
		<>
			<Avatar
				sx={{ bgcolor: minutes > 2 ? lightGreen : deepOrange[500] }}
				alt="Remy Sharp"
				src="/broken-image.jpg"
			>
				{seconds === 0 && minutes === 0 ? null : (
					<h6>
						{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
					</h6>
				)}
			</Avatar>
		</>
	);
}

export default SessionExpirationSection;
