import React from 'react';
import { ProjectsTapProvider } from './tap';
import { ProjectsManagerProvider } from './manager';

function ProjectsProvider({ children }) {
	return (
    	<ProjectsTapProvider>
			<ProjectsManagerProvider>
				{children}
			</ProjectsManagerProvider>
		</ProjectsTapProvider>
	);
}

export default ProjectsProvider;
