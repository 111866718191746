import React from 'react';
import { CashbackProvider } from './Cashback';
import { LoadBalanceProvider } from './LoadBalance';
import { RegisterCampaignProvider } from './RegisterCampaign';

function CencopayProvider({ children }) {
	return (
		<CashbackProvider>
			<LoadBalanceProvider>
				<RegisterCampaignProvider>{children}</RegisterCampaignProvider>
			</LoadBalanceProvider>
		</CashbackProvider>
	);
}

export default CencopayProvider;
