import React from 'react';
import Papa from 'papaparse';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useAuth } from '../../../hooks/auth';

import apiCartaoSISF from '../../../services/apiCartaoSISF';
import apiRhSgi from '../../../services/apiRhSgi';

const UnblockSendPasswordContext = React.createContext({});
UnblockSendPasswordContext.displayName =
	'Desbloqueio de Cartão e envio de Senha';

export function UnblockSendPasswordProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [cpf, setCpf] = React.useState('');
	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [openModal, setOpenModal] = React.useState(false);
	const [selectedCard, setSelectedCard] = React.useState({});
	const [phone, setPhone] = React.useState('');
	const [data, setData] = React.useState([]);

	const handleCleanData = () => {
		setIsLoading(false);
		setOpenModal(false);
		setSelectedCard({});
		setPhone('');
		setData([]);
	};

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setCpf('');
		setActiveStep(0);
	};

	const handleCloseModal = () => {
		setSelectedCard({});
		setOpenModal(false);
	};

	const handleOpenModal = (data) => {
		setSelectedCard(data);
		setOpenModal(true);
	};

	const handleConsult = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		await apiCartaoSISF
			.get(`/consulta-cartao-cpf/${cpf?.replace(/[^\d]+/g, '')}`)
			.then((res) => {
				if (res.data.length > 0) {
					setData(res.data);
					handleInsertLog({
						recursoApi: `/consulta-cartao-cpf`,
						status: '200',
						tipoAcao: 'Consult',
						detalhesAcao: `Consulta cliente: ${cpf?.replace(/[^\d]+/g, '')}`,
					});
					onChangeLoading({ open: false });
					setIsLoading(false);
					handleNext();
				} else {
					onChangeLoading({ open: false });
					setIsLoading(false);
					onChangeNotify({
						open: true,
						class: 'warning',
						msg: 'CPF não é cliente do novo cartão visa gbarbosa',
					});
				}
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleSendPassword = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const payload = {
			nrCartao: selectedCard?.nrCartao.slice(3),
			nrCPF: cpf?.replace(/\D/g, ''),
			nrCelular: phone?.replace(/\D/g, ''),
		};

		await apiCartaoSISF
			.post(`/envio-sms`, payload)
			.then((res) => {
				onChangeLoading({ open: true });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Senha enviada com sucesso.',
				});
				setIsLoading(false);
				handleInsertLog({
					recursoApi: `/envio-sms`,
					status: '200',
					tipoAcao: 'Consult',
					detalhesAcao: `Envio senha: cpf: ${cpf} | celular: ${phone} | Final Cartão: ${selectedCard?.nrCartao.slice(
						-4
					)}`,
				});
				setPhone('');
				setOpenModal(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleUnblockCard = async (item) => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const payload = {
			nrCartao: item?.nrCartao.slice(3),
			nrCPF: cpf?.replace(/\D/g, ''),
		};

		await apiCartaoSISF
			.post(`/desbloquear-cartao`, payload)
			.then((res) => {
				onChangeLoading({ open: true });
				handleConsult();
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Cartão desbloqueado com sucesso.',
				});
				setIsLoading(false);
				handleInsertLog({
					recursoApi: `/desbloquear-cartao`,
					status: '200',
					tipoAcao: 'Update',
					detalhesAcao: `Desbloqueio cartao: cpf: ${cpf} | Final Cartao: ${selectedCard?.nrCartao.slice(
						-4
					)}`,
				});
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	/**
	 * Insere um log no sistema.
	 *
	 * @param {Object} param
	 * @param {string} [params.recursoApi=null]
	 * @param {string} [params.status=null]
	 * @param {string} [params.mensagemErro=null]
	 * @param {string} [params.tipoAcao=null]
	 * @param {string} [params.detalhesAcao=null]
	 */
	const handleInsertLog = async ({
		recursoApi = null,
		status = null,
		mensagemErro = null,
		tipoAcao = null,
		detalhesAcao = null,
	} = {}) => {
		const payload = {
			loginAd: user.login_ad,
			nomeAd: user.name,
			matriculaAd: user.matricula,
			tipoAcao: tipoAcao,
			detalhesAcao: detalhesAcao,
			modulo: 'Cartao',
			tela: 'Desbloqueio e Envio de Senha',
			recursoApi: recursoApi,
			status: status,
			mensagemErro: mensagemErro,
			appId:
				process.env.REACT_APP_PROD === 'true'
					? process.env.REACT_APP_ID_PROD
					: process.env.REACT_APP_ID_HMG,
		};

		await apiRhSgi
			.post('/log/insert', payload)
			.then((res) => {
				onChangeLoading({ open: false });
			})
			.catch((error) => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: `Aconteceu algum erro!`,
				});
			});
	};

	return (
		<UnblockSendPasswordContext.Provider
			value={{
				cpf,
				setCpf,
				activeStep,
				handleNext,
				handleBack,
				isLoading,
				data,
				handleConsult,
				openModal,
				handleCloseModal,
				handleOpenModal,
				selectedCard,
				handleSendPassword,
				handleUnblockCard,
				phone,
				setPhone,
				handleInsertLog,
				handleCleanData,
			}}
		>
			{children}
		</UnblockSendPasswordContext.Provider>
	);
}

export function useUnblockSendPassword() {
	const context = React.useContext(UnblockSendPasswordContext);

	if (!context)
		throw new Error(
			'useUnblockSendPassword must be used within an AuthProvider'
		);

	return context;
}
