import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

import MainLayout from '../layouts/MainLayout';
import MinimalLayout from '../layouts/MinimalLayout';
import useTransactionLogger from '../hooks/useTransactionsLog';

export default function RouteWrapper({
    component: Component,
    isPrivate = false,  
    ...rest
}) {
    const { user, isVerifying } = useAuth();
    const signed = user;
    useTransactionLogger(user?.login_ad);

    if (!signed && isPrivate && !isVerifying) {
        return <Redirect to="/" />;
    }

    if (signed && !isPrivate && !isVerifying) {
        return <Redirect to="/dashboard" />;
    }

    const Layout = signed ? MainLayout : MinimalLayout;

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
};
