import React from 'react';

import { CashbackProvider } from './Cashback';
import { LoadBalanceProvider } from "./LoadBalance";
import { RegisterCampaignProvider } from './RegisterCampaign';
import { BenefitCardProvider } from './BenefitCard';
import { DeviceBlockingFraudProvider } from "./DeviceBlockingFraud";
import { AccountBlockSupportProvider } from './AccountBlocksSupport';
import { AccountBlockingFraudProvider } from "./AccountBlockingFraud";
import { useAuth } from '../../../hooks/auth';
import { ExpenseFilesProvider } from './ExpenseFiles';

function CencopayProvider({ children }) {

	return (
		<AccountBlockSupportProvider>
			<AccountBlockingFraudProvider>
				<DeviceBlockingFraudProvider>
					<LoadBalanceProvider>
						<CashbackProvider>
							<RegisterCampaignProvider>
                                <BenefitCardProvider>
									<ExpenseFilesProvider>
                                    	{children}
									</ExpenseFilesProvider>
                                </BenefitCardProvider>
							</RegisterCampaignProvider>
						</CashbackProvider>
					</LoadBalanceProvider>
				</DeviceBlockingFraudProvider>
			</AccountBlockingFraudProvider>
		</AccountBlockSupportProvider>
	)	
}

export default CencopayProvider;
