import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';
import apiProjects from '../../../services/apiProjects';


const ProjectsTapContext = React.createContext({
	onChangeProjectsTapCliente: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

ProjectsTapContext.displayName = 'Contexto de Projetos - TAP';



export function ProjectsTapProvider({ children }) {
	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [isSuccess, setIsSuccess] = React.useState(false);
	const [formsData, setFormsData] = React.useState({});
	const { onChangeLoading } = useLoading();
	const { onChangeNotify } = useNotify();
	const initialState = {
		nomeProjeto: '',
		tipoProjeto: '',
		areaDemandante: '',
		categoria: '',
		prioridade: '',
		situacaoAtual: '',
		escopoProjeto: '',
		sistemasImpactados: [],
		areasEnvolvidas: [],
		retornoFinanceiro: '',
		responsavelNegocio: '',
		consideracoesAdicionais: '',
		attachments: null
	};


	const handleNext = () => {
		setActiveStep((prevActiveStep) =>
			prevActiveStep === 0 ? 1 : prevActiveStep
		);
	};

	const handleBack = () => {
		setActiveStep(0);
	};

	const handleSubmit = async (data) => {
		try {
		  setIsLoading(true);
			await apiProjects.post('/projects', data);
			onChangeNotify({
				open: true,
				msg: 'Projeto cadastrado com sucesso!',
				type: 'success',
			});
			setIsSuccess(true);
		} catch (error) {
			onChangeNotify({
				open: true,
				msg: 'Erro ao cadastrar projeto!',
				type: 'error',
			});
		} finally {
		onChangeLoading({
			open: false,
			msg: 'Carregando...',
		});
	};
}
	const formik = useFormik({
		initialValues: initialState,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
		},
	});

	useEffect(() => {
		handleBack();
	}, []);	
	
	return (
		<ProjectsTapContext.Provider
			value={{
				activeStep,
				setActiveStep,
				handleNext,
				handleBack,
				isLoading,
				setIsLoading,
				formsData,
				setFormsData,
				handleSubmit,
				isSuccess,
			}}
		>
			{children}
		</ProjectsTapContext.Provider>
	);
}

export function useProjectsTap() {
	const context = React.useContext(ProjectsTapContext);

	if (!context)
		throw new Error('useCashbackClient must be used within an AuthProvider');

	return context;
}
