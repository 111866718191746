import Papa from 'papaparse';
import React, { useContext, useEffect, useState, useCallback } from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import apiCencopay from '../../../services/apiCencopay-gwUsersS3';

const DeviceBlockFraudContext = React.createContext({});
DeviceBlockFraudContext.displayName = 'Contexto Fraude de Consulta Device Bloqueado - Cencopay';

export function DeviceBlockingFraudProvider({ children }) {
    const { onChangeNotify } = useNotify();
    const { onChangeLoading } = useLoading();

    const [cpf, setCpf] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [blocksDevice, setBlockDevice] = useState([]);
    const [selectedBlocks, setSelectedBlocks] = useState({});

    const [fileName, setFileName] = useState('');
    const [hasNegativeValue, setHasNegativeValue] = useState(false);
    const [openModalMassivo, setOpenModalMassivo] = useState(false);
    const [blockAll, setBlockAll] = useState(false);

    const [devices, setDevices] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [deviceList, setDeviceList] = useState([]);

    const [deviceId, setDeviceId] = useState('');
    const [isCpf, setIsCpf] = useState(true);

    const [blockDeviceCpf, setBlockDeviceCpf] = useState([]);
    const [functionalities, setFunctionalities] = useState({
        balance_view_blocked: true,
        statement_view_blocked: true,
        purchase_authorization_blocked: true,
        transfer_blocked: true,
        cashin_blocked: true,
        cashout_blocked: true,
    });

    const cleanCpf = (cpf) => cpf.replace(/\.|-/g, '');

    const handleSelectedBlock = (deviceId, blockId) => {
        setSelectedBlocks((prev) => ({
            ...prev,
            [deviceId]: {
                ...prev[deviceId],
                [blockId]: [blockId]
            }
        }));
    };

    const handleInputChangeCpf = (e) => {
        setCpf(e.target.value);
    };

    const handleInputChangeDeviceId = (e) => {
        const value = e.target.value.toLowerCase();
        setDeviceId(value);
    };

    const handleOpenModalMassivo = () => {
        setOpenModalMassivo(true);
    }

    const handleCloseModalMassivo = () => {
        setOpenModalMassivo(false);
        setHasNegativeValue(false);
        setFileName('');
    }

    const toggleInputType = () => {
        setIsCpf(!isCpf);
        setCpf('');
        setDeviceId('');
        setBlockDeviceCpf([]);
        setBlockDevice([]);
    };

    const handleNext = React.useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep]);

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    useEffect(() => {
        if (cpf === '') {
            setBlockDeviceCpf([]);
        }
        if (deviceId === '') {
            setBlockDevice([]);
        }
    }, [cpf, deviceId]);

    const getAllBlocks = async () => {
        try {
            const response = await apiCencopay.get(`/Blocks`);
            const blocks = response.data;
            setBlocks(blocks);
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                message: "Erro ao buscar bloqueios."
            });
            throw error;
        }
    }

    const updateBlocksDevice = async (deviceId, blockMambo, blockBeneficio, blockCPF) => {
        try {
            if(blockCPF){
                const response = await apiCencopay.put(`/Device/block-device-cpf/${blockCPF}`, {
                    device_id: deviceId,
                    block_mambo: blockMambo,
                    block_beneficio: blockBeneficio
                });
                if (response.status === 200 || response.status === 204) {
                    onChangeNotify({
                        open: true,
                        class: 'success',
                        message: "Bloqueio atualizado com Sucesso."
                    });
                    return true;
                }
            }else{
                const response = await apiCencopay.put(`/Device/block-device`, {
                    device_id: deviceId,
                    block_mambo: blockMambo,
                    block_beneficio: blockBeneficio
                });
                if (response.status === 200 || response.status === 204)  {
                    onChangeNotify({
                        open: true,
                        class: 'success',
                        message: "Bloqueio atualizado com Sucesso."
                    });
                    return true;
                }
            }

            
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                message: "Erro ao atualizar bloqueio."
            });
            console.log("Erro ao atualizar bloqueio:", error);
            return false;
        } finally {
            setIsLoading(false);
        }
        return true;
    };

    const handleBlocksDevicesCPF = async (payload) => {
        const {deviceID, deviceCPF, blocckID, } = payload
        try {
            const response = await apiCencopay.put(`/Device/block-device-cpf/${deviceCPF}`, {
                device_id: formattedDeviceId(deviceID),
                block_mambo: blocckID,
                block_beneficio: blocckID
            });
            if (response.status === 200 || response.status === 204) {
                onChangeNotify({
                    open: true,
                    class: 'success',
                    message: "Bloqueio atualizado com Sucesso."
                });
                return true;
            }
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                message: "Erro ao atualizar bloqueio."
            });
            console.log("Erro ao atualizar bloqueio:", error);
            return false;
        } finally {
            setIsLoading(false);
        }
        return true;
    };

    const handleWalletChange = (wallet) => {
        setFunctionalities({
            balance_view_blocked: false,
            statement_view_blocked: false,
            purchase_authorization_blocked: false,
            transfer_blocked: false,
            cashin_blocked: false,
            cashout_blocked: false,
        });
    };

    const getData = async () => {
        if (!cpf) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Por favor, preencha o CPF para realizar a consulta.',
            });
            return;
        }

        setIsLoading(true);
        onChangeLoading({
            open: true,
            msg: 'Consultando...',
        });

        const cleanCpf = cpf.toString().replace(/\.|-/gm, '');

        try {
            const response = await apiCencopay.get(`/Device/blocks-devices-cpf/${cleanCpf}`);

            if (response.status === 200 && response.data) {
                const rows = Array.isArray(response.data) ? response.data : [response.data];
                setBlockDeviceCpf(rows);
                onChangeNotify({
                    open: true,
                    class: 'success',
                    msg: `Consulta efetuada com Sucesso!`,
                });
            } else {
                setBlockDeviceCpf([]);
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Nenhum dado encontrado para o CPF fornecido.',
                });
            }
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro na busca, tente novamente!',
            });
        } finally {
            setIsLoading(false);
            onChangeLoading({ open: false });
        }
    };

  
    const handleInsertBlocksMassivo = useCallback(async () => {
        if (deviceList.length === 0) {
            onChangeNotify({
                open: true,
                class: 'warning',
                msg: 'Nenhum dado disponível para realizar o bloqueio massivo.',
            });
            return;
        }

        const payloads = deviceList.map(({ device_id, block_mambo, block_beneficio }) => ({
            device_id: device_id.toLowerCase(),
            block_mambo: block_mambo && block_mambo.trim().toUpperCase() !== 'I' && block_mambo.trim().toUpperCase() !== 'N' 
                ? block_mambo.trim() 
                : undefined,
            block_beneficio: block_beneficio && block_beneficio.trim().toUpperCase() !== 'I' && block_beneficio.trim().toUpperCase() !== 'N' 
                ? block_beneficio.trim() 
                : undefined,
        })).filter(payload => payload.block_mambo || payload.block_beneficio); 

        if (payloads.length === 0) {
            onChangeNotify({
                open: true,
                class: 'warning',
                msg: 'Nenhum bloqueio válido foi encontrado no arquivo CSV.',
            });
            return;
        }

        let isMounted = true;

        try {
            onChangeLoading({
                open: true,
                msg: 'Carregando...',
            });

           const response = await apiCencopay.put(`/Device/block-device-massive`, payloads);

            if (response.status === 200) {
                if (isMounted) {
                    onChangeNotify({
                        open: true,
                        class: 'success',
                        msg: 'Bloqueio massivo realizado com sucesso!',
                    });
                }
            } else {
                console.error('Erro na resposta da API:', response);
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Erro ao realizar o bloqueio massivo. Tente novamente!',
                });
            }
        } catch (error) {
            console.error('Erro ao tentar realizar o bloqueio massivo:', error);
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu um erro ao tentar realizar o bloqueio massivo. Tente novamente!',
            });
        } finally {
            if (isMounted) {
                onChangeLoading({ open: false });
                handleCloseModalMassivo();
            }
        }

        return () => {
            isMounted = false;
        };
    }, [onChangeLoading, onChangeNotify, handleCloseModalMassivo, deviceList]);

    const resetForm = useCallback(() => {
        setCpf('');
        setDeviceId('');
        setBlockDeviceCpf([]);
        setBlockDevice([]);
    }, []);

    useEffect(() => {
        resetForm();
    }, [location.pathname, resetForm]);

    const handleDocument = (event) => {
        setFileName('');

        setHasNegativeValue(false);

        Papa.parse(event.target.files[0], {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: 'greedy',
            complete: (results) => {
                const parsedData = results.data;
                const formattedData = parsedData.map(row => ({
                    device_id: row.device_id ? String(row.device_id).trim() : row.device_id,
                    block_mambo: row.block_mambo ? String(row.block_mambo).trim() : "",
                    block_beneficio: row.block_beneficio ? String(row.block_beneficio).trim() : "",
                }));
                setDeviceList(formattedData);
                setFileName(event.target.files[0].name);
            },
            error: (error) => {
                console.error('Erro ao fazer parsing do arquivo CSV:', error);
            }
        });
    };

    const getDataBlockDeviceId = async () => {
        if (!deviceId) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Por favor, preencha o Id do aparelho celular para realizar a consulta.',
            });
            return;
        }

        const formattedDeviceId = deviceId.toLowerCase();
        console.log(`DeviceId Formatted =>: ${formattedDeviceId}`);

        setIsLoading(true);
        onChangeLoading({
            open: true,
            msg: 'Consultando...',
        });

        try {
            const url = `/Device/blocks-device/${formattedDeviceId}`;

            const res = await apiCencopay.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (res.status === 200 && res.data) {
                const rows = Array.isArray(res.data) ? res.data : [res.data];
                setBlockDevice(rows);
            } else {
                setBlockDevice([]);
            }

            onChangeNotify({
                open: true,
                class: 'success',
                msg: `Consulta realizada com sucesso!`,
            });
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro na busca, tente novamente!',
            });
        } finally {
            setIsLoading(false);
            onChangeLoading({ open: false });
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const maskCpf = (cpf) => {
        if (!cpf) return '';
        const unmaskedCpf = cpf.replace(/\D/g, '');
        if (unmaskedCpf.length !== 11) return cpf;
        return unmaskedCpf.replace(/(\d{3})\d{6}(\d{2})/, '$1.***.***-$2');
    };

    const fetchDevices = async (devicesCpf) => {
        setIsLoading(true)

        try {
            const response = await apiCencopay.get(`/Device/blocks-devices/${devicesCpf}`);
            if (response.status === 200 && response.data) {
                setDevices(response.data);
            } else {
                setDevices([]);
            }
        } catch (e) {
            console.log('Erro ao buscar os aparelhos:', e);

            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro, tente novamente!'
            });
        } finally {
            setIsLoading(false);
        }
    }

    const handleBlockAllChange = (blockType) => {
        setBlockAll(!blockAll);
        const devicesToBlock = isCpf ? blockDeviceCpf : blocksDevice;

        console.log('dados para bloqueio', devicesToBlock)
        devicesToBlock.forEach(device => {
            if(!device.biometry) return;
            
            updateBlocksDevice(device.device_id, blockType, blockType).then(() => {
                
                isCpf ? refreshData() : refreshDataBlockDeviceId();
                
            }).catch(() => {
                console.log("Erro ao atualizar bloqueios.")
            });
        });
    };

    const refreshData = async () => {
        

        setIsLoading(true);
        onChangeLoading({
            open: true,
            msg: 'Atualizando...',
        });

        const cleanCpf = cpf.toString().replace(/\.|-/gm, '');

        try {
            const response = await apiCencopay.get(`/Device/blocks-devices-cpf/${cleanCpf}`);

            if (response.status === 200 && response.data) {
                const rows = Array.isArray(response.data) ? response.data : [response.data];
                setBlockDeviceCpf(rows);
                onChangeNotify({
                    open: true,
                    class: 'success',
                    msg: `Bloqueios atualizados com Sucesso!`,
                });
            } else {
                setBlockDeviceCpf([]);
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Nenhum dado encontrado para o CPF fornecido.',
                });
            }
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro na busca, tente novamente!',
            });
        } finally {
            setIsLoading(false);
            onChangeLoading({ open: false });
        }
    };

    const refreshDataBlockDeviceId = async () => {
        if (!deviceId) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Por favor, preencha o Id do aparelho celular para realizar a consulta.',
            });
            return;
        }

        const formattedDeviceId = deviceId.toLowerCase();
        console.log(`DeviceId Formatted =>: ${formattedDeviceId}`);

        setIsLoading(true);
        onChangeLoading({
            open: true,
            msg: 'Atualizando...',
        });

        try {
            const url = `/Device/blocks-device/${formattedDeviceId}`;

            const res = await apiCencopay.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (res.status === 200 && res.data) {
                const rows = Array.isArray(res.data) ? res.data : [res.data];
                setBlockDevice(rows);
            } else {
                setBlockDevice([]);
            }

            onChangeNotify({
                open: true,
                class: 'success',
                msg: `Atualização realizada com sucesso!`,
            });
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro na busca, tente novamente!',
            });
        } finally {
            setIsLoading(false);
            onChangeLoading({ open: false });
        }
    };

    return (
        <DeviceBlockFraudContext.Provider
            value={{
                cpf,
                setCpf,
                getData,
                refreshData,
                refreshDataBlockDeviceId,
                isLoading,
                openModal,
                activeStep,
                handleNext,
                handleBack,
                setDeviceId,
                blocksDevice,
                setActiveStep,
                blockDeviceCpf,
                handleCloseModal,
                getDataBlockDeviceId,
                toggleInputType,
                isCpf,
                deviceId,
                maskCpf,
                handleInputChangeCpf,
                handleInputChangeDeviceId,
                selectedBlocks,
                handleSelectedBlock,
                fileName,
                handleDocument,
                openModalMassivo,
                hasNegativeValue,
                handleOpenModalMassivo,
                handleCloseModalMassivo,
                handleInsertBlocksMassivo,
                blocks,
                updateBlocksDevice,
                getAllBlocks,
                handleBlockAllChange,
                blockAll,
                handleBlocksDevicesCPF,
                resetForm,

                // Retirei do front-end
                fetchDevices,
                functionalities,
                handleWalletChange,
                setFunctionalities,
            }}
        >
            {children}
        </DeviceBlockFraudContext.Provider>
    );
}

export function useDeviceBlockingFraud() {
    const context = useContext(DeviceBlockFraudContext);

    if (!context)
        throw new Error('DeviceBlockingFraud must be used within an AuthProvider');

    return context;
}
