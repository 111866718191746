import React, { useContext, useState } from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import apiCencopay from '../../../services/apiCencopay-gwUsersS3';

const AccountBlockSupportContext = React.createContext({});
AccountBlockSupportContext.displayName = 'Contexto de Atendimento Bloqueio de Conta - Cencopay';

export function AccountBlockSupportProvider({ children }) {
    const { onChangeNotify } = useNotify();
    const { onChangeLoading } = useLoading();

    const [cpf, setCpf] = useState('');
    const [blocks, setBlocks] = useState([]);
    const [userData, setUserData] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) =>
            prevActiveStep === 0 ? 1 : prevActiveStep
        );
    };

    const handleBack = () => {
        setActiveStep(0);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCpf('');
    };

    const getData = async () => {
        if (!cpf && cpf === '') {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Por favor, preencha o CPF para realizar a consulta.',
            });
            return;
        }

        onChangeLoading({
            open: true,
            msg: 'Consultando...',
        });

        const cleanCpf = cpf.toString().replace(/\.|-/gm, '');

        try {
            const [userResponse, blocksResponse] = await Promise.all([
                apiCencopay.get(`/users/sisf/${cleanCpf}`),
                apiCencopay.get(`/users/blocks/${cleanCpf}`)
            ]);

            console.log('dados do cliente',userResponse)

            if (userResponse.status === 200) {
                // const rows = Array.isArray(userResponse.data) ? userResponse.data : [userResponse.data];
                setUserData(userResponse.data);
                setOpenModal(true);

                onChangeNotify({
                    open: true,
                    class: 'success',
                    msg: `Consulta realizada com sucesso!`,
                });
            } else {
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Nenhum dado encontrado para o CPF fornecido.',
                });
            }

            if (blocksResponse.status === 200) {
                const blocks = Array.isArray(blocksResponse.data) ? blocksResponse.data : [blocksResponse.data];
                setBlocks(blocks);
            }

        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro na busca, tente novamente!',
            });
        } finally {
            setIsLoading(false);
            onChangeLoading({ open: false });
        }
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const maskCpf = (cpf) => {
        if (!cpf) return '';
        const unmaskedCpf = cpf.replace(/\D/g, '');
        if (unmaskedCpf.length !== 11) return cpf;
        return unmaskedCpf.replace(/(\d{3})\d{6}(\d{2})/, '$1.***.***-$2');
    };

    const maskCellphone = (cellphone) => {
        if (!cellphone) return '';
        const unmaskedCellphone = cellphone.replace(/\D/g, '');

        if (unmaskedCellphone.length === 11) {
            return unmaskedCellphone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } if (unmaskedCellphone.length === 10) {
            return unmaskedCellphone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        }
        if (unmaskedCellphone.length === 13) {
            return unmaskedCellphone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
        }

        return cellphone;
    };

    function WalletBalanceProvider(balance) {
        const formattedBalance = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(balance);
        const displayBalance = balance > 0 ? formattedBalance : 'R$ 0,00';
        return displayBalance;
    }

    const validateCpf = (cpf) => {
        const unmaskedCpf = cpf.replace(/\D/g, '');

        if (unmaskedCpf.length !== 11) return false;

        let sum = 0;
        let remainder;

        for (let i = 1; i <= 9; i++) {
            sum += parseInt(unmaskedCpf.substring(i - 1, i)) * (11 - i);
        }

        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(unmaskedCpf.substring(9, 10))) return false;

        sum = 0;

        for (let i = 1; i <= 10; i++) {
            sum += parseInt(unmaskedCpf.substring(i - 1, i)) * (12 - i);
        }

        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(unmaskedCpf.substring(10, 11))) return false;

        return true;
    };

    return (
        <AccountBlockSupportContext.Provider
            value={{
                cpf,
                blocks,
                setCpf,
                maskCpf,
                getData,
                userData,
                isLoading,
                openModal,
                handleBack,
                activeStep,
                handleNext,
                validateCpf,
                setOpenModal,
                maskCellphone,
                handleOpenModal,
                handleCloseModal,
                WalletBalanceProvider,
            }}
        >
            {children}
        </AccountBlockSupportContext.Provider>
    );
}

export function useAccountBlockSupport() {
    const context = useContext(AccountBlockSupportContext);

    if (!context)
        throw new Error(
            'useAccountBlockSupport must be used within an AuthProvider'
        );

    return context;
}
