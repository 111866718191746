import axios from 'axios';
import config from '../config';

const apiCencopay = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_CENCOPAY_PROD
			: process.env.REACT_APP_CENCOPAY_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key':
			process.env.REACT_APP_PROD === 'true'
				? config.apikeyCencopay
				: config.apikeyCencopayDev,
	},
});

export default apiCencopay;
