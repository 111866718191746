import React from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiCartaoBeneficio from '../../../services/apiCartaoBaneficio';
import apiCupomFiscal from '../../../services/apiCupomFiscal';
import apiCencopay from '../../../services/apiCencopay-gwUsersS3';

const BenefitCardContext = React.createContext({
	onChangeBenefitCardClient: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

BenefitCardContext.displayName = 'Contexto de BenefitCard - Cencopay';

export function BenefitCardProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
    const { user } = useAuth();

	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [cpf, setCpf] = React.useState('');
	const [data, setData] = React.useState([]);
    const [isBlocked, setIsBlocked] = React.useState(false);
	

    const userType = user?.group;
	const itemsPerPage = 5;

	const totalpages = 0;

	const handleNext = () => {
		setActiveStep((prevActiveStep) =>
			prevActiveStep === 0 ? 1 : prevActiveStep
		);
	};

	const handleBack = () => {
		setActiveStep(0);
        setCpf('');
	};

	const handleConsult = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true, msg: 'Consultando...' });
        const cleanCpf = cpf.replace(/\D/g, '');
		try {
			const options = {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
					'x-api-key': process.env.REACT_APP_X_API_KEY_CENCOPAY_BENEFITCARD,
				},
			};
			const res = await apiCartaoBeneficio.get(`/get-benefits-sisf/${cleanCpf}`, options);
			setData(res.data);
            handleNext();
		} catch (error) {
			console.error('Erro ao consultar:', error);
			onChangeNotify({
				open: true,
				class: 'error',
				msg: `Aconteceu algum erro na busca, tente novamente!`,
			});
		} finally {
			setIsLoading(false);
			onChangeLoading({ open: false });
		}
	};

	const handleGetTicket = async (loja, pdv, cupom, dataFiscal) => {
		setIsLoading(true);
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		try {
			const response = await apiCupomFiscal.post('/ConsultaCupomFiscal', {
				loja,
				pdv,
				cupom,
				dataFiscal,
			});

			setIsLoading(false);
			onChangeLoading({
				open: false,
				msg: '',
			});
			if (response.data.status === 'cupom nao encontrado') {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Cupom não encontrado, tente novamente!',
				});
				return null;
			}
			return response.data;
		} catch (error) {
			setIsLoading(false);
			onChangeLoading({
				open: false,
				msg: '',
			});
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Aconteceu algum erro na busca, tente novamente!',
			});
			return null;
		}
	};

    const getCreditCardBenefitConsumption = async (cpf, page, pagesize) => {
        try {
            const options = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            };
            const res = await apiCencopay.get(
                `/CreditCardBenefitConsumption/${cpf}/${page}/${pagesize}`,
                options
            );
            return res.data;
        } catch (error) {
            console.error('Erro ao consultar:', error);
            onChangeNotify({
                open: true,
                class: 'error',
                msg: `Aconteceu algum erro na busca, tente novamente!`,
            });
            return [];
        }
    }

	const saveBlockToDatabase = React.useCallback(async (blockId) => {
			try {
				setIsLoading(true)
				onChangeLoading({
					open: true,
					msg: 'Atualizando...',
				});
				if (!blockId || typeof blockId !== 'string') {
					throw new Error('ID de bloqueio inválido');
				}
				
				const cleanedCpf = cpf?.replace(/\D/g, '');
	
				if (!cleanedCpf) {
					throw new Error('CPF do usuário não está disponível para atualização');
				}				
	
			   const response = await apiCencopay.get(`/Device/blocks-devices-cpf/${cleanedCpf}`);

			   if (response.status === 200 && response.data) {
					const rows = Array.isArray(response.data) ? response.data : [response.data];
					rows.forEach(device => {
						updateBlocksDevice(device.device_id, blockId, blockId).then(() => {
							onChangeNotify({
								open: true,
								class: 'success',
								msg: 'Bloqueios dos devices atualizados...'
							});
						}).catch(() => {
							onChangeNotify({
								open: true,
								class: 'error',
								msg: 'Erro ao atualizar bloqueios.'
							});
						});
					});
				} else {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Nenhum dado encontrado para o CPF fornecido.',
					});
				}			 
				
			} catch (error) {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: "Erro ao atualizar bloqueio!"
				});
				console.error('Erro ao salvar bloqueio:', error.message);
				if (error.response) {
					console.error('Detalhes do erro:', error.response.data);
				}
			}finally{
				getBlocks();
			}
		},[cpf, onChangeNotify]);

	const getBlocks = React.useCallback(async () => {
		const cleanedCpf = cpf.replace(/\D/g, '');
	
		try {
			const blocksResponse = await apiCencopay.get(`/Device/blocks-devices-cpf/${cleanedCpf}`);
	
			if (blocksResponse.status === 200 && Array.isArray(blocksResponse.data)) {
				const hasBlocks = blocksResponse.data.some(block => block.block_id !== "I");
				setIsBlocked(hasBlocks);
			} else {
				setIsBlocked(false);
			}
	
		} catch (error) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Aconteceu algum erro na busca, tente novamente!',
			});
		} finally {
			setIsLoading(false);
			onChangeLoading({ open: false });
		}
	}, [cpf, onChangeLoading, onChangeNotify]);	
		
	const updateBlocksDevice = async (deviceId, blockMambo, blockBeneficio) => {
        try {
            const response = await apiCencopay.put(`/Device/block-device`, {
                device_id: deviceId,
                block_mambo: blockMambo,
                block_beneficio: blockBeneficio
            });
            if (response.status === 200) {
                onChangeNotify({
                    open: true,
                    class: 'success',
                    msg: "Bloqueio atualizado com Sucesso."
                });
                return true;
            }
        } catch (error) {
            onChangeNotify({
                open: true,
                class: 'error',
                msg: "Erro ao atualizar bloqueio."
            });
            console.log("Erro ao atualizar bloqueio:", error);
            return false;
        } finally {
            setIsLoading(false);
        }
        return true;
    };


	return (
		<BenefitCardContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				setActiveStep,
				handleConsult,
				isLoading,
				setIsLoading,
				cpf,
				setCpf,
				data,
				itemsPerPage,
				totalpages,
				handleGetTicket,
                isBlocked,
                setIsBlocked,
                userType,
                getCreditCardBenefitConsumption,
				saveBlockToDatabase,
				getBlocks,
			}}
		>
			{children}
		</BenefitCardContext.Provider>
	);
}

export function useBenefitCard() {
	const context = React.useContext(BenefitCardContext);

	if (!context)
		throw new Error('useBenefitCardClient must be used within an AuthProvider');

	return context;
}
