import React from 'react';
import { ProductsCdcProvider } from './productsCdc';
import { ProfileManagementProvider } from './profileManagement';
import { UserManagementProvider } from './userManagement';
import { GroupStoreManagementProvider } from './groupStoreManagement';
import { ReportAbmProvider } from './ReportAbm';
import { ReportAccessProvider } from './ReportAccess';
import { ProgressiveDiscountProvider } from './progressiveDiscount';
import { RhSgiProvider } from './RhSgi';

function ProvidersSecurity({ children }) {
	return (
		<ProductsCdcProvider>
			<ProfileManagementProvider>
				<UserManagementProvider>
					<GroupStoreManagementProvider>
						<ReportAbmProvider>
							<ReportAccessProvider>
								<ProgressiveDiscountProvider>
									<RhSgiProvider>{children}</RhSgiProvider>
								</ProgressiveDiscountProvider>
							</ReportAccessProvider>
						</ReportAbmProvider>
					</GroupStoreManagementProvider>
				</UserManagementProvider>
			</ProfileManagementProvider>
		</ProductsCdcProvider>
	);
}

export default ProvidersSecurity;
