import React from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiCupomFiscal from '../../../services/apiCupomFiscal';

const ExpenseFilesContext = React.createContext({
	onChangeExpenseFilesClient: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

ExpenseFilesContext.displayName = 'Contexto de ExpenseFiles - Cencopay';

export function ExpenseFilesProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
    const { user } = useAuth();

	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState([]);
    const [isBlocked, setIsBlocked] = React.useState(true);
	// const [dataTicket, setDataTicket] = React.useState([]);

    const userType = user?.group;
	const itemsPerPage = 5;

	const totalpages = 0;

	const handleNext = () => {
		setActiveStep((prevActiveStep) =>
			prevActiveStep === 0 ? 1 : prevActiveStep
		);
	};

	const handleBack = () => {
		setActiveStep(0);
	};

	const handleConsult = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true, msg: 'Consultando...' });

		try {
			setActiveStep(1);
		} catch (error) {
			console.error('Erro ao consultar:', error);
			onChangeNotify({
				open: true,
				class: 'error',
				msg: `Aconteceu algum erro na busca, tente novamente!`,
			});
		} finally {
			setIsLoading(false);
			onChangeLoading({ open: false });
		}
	};

	const handleGetTicket = async (loja, pdv, cupom, dataFiscal) => {
		setIsLoading(true);
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		try {
			const response = await apiCupomFiscal.post('/ConsultaCupomFiscal', {
				loja,
				pdv,
				cupom,
				dataFiscal,
			});

			setIsLoading(false);
			onChangeLoading({
				open: false,
				msg: '',
			});
			if (response.data.status === 'cupom nao encontrado') {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Cupom não encontrado, tente novamente!',
				});
				return null;
			}
			return response.data;
		} catch (error) {
			setIsLoading(false);
			onChangeLoading({
				open: false,
				msg: '',
			});
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Aconteceu algum erro na busca, tente novamente!',
			});
			return null;
		}
	};

	return (
		<ExpenseFilesContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				setActiveStep,
				handleConsult,
				isLoading,
				setIsLoading,
				data,
				itemsPerPage,
				totalpages,
				handleGetTicket,
                isBlocked,
                setIsBlocked,
                userType,
			}}
		>
			{children}
		</ExpenseFilesContext.Provider>
	);
}

export function useExpenseFiles() {
	const context = React.useContext(ExpenseFilesContext);

	if (!context)
		throw new Error('useExpenseFilesClient must be used within an AuthProvider');

	return context;
}
