import axios from 'axios';
import config from '../config';

const apiCargaSaldoCencopay = axios.create({
	baseURL:
		// 'http://localhost:3000',
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_API_CARGA_SALDO_CENCOPAY_PROD
			: process.env.REACT_APP_API_CARGA_SALDO_CENCOPAY_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key':
			process.env.REACT_APP_PROD === 'true'
				? config.xapikey
				: '7NrqjTKpp45GxcBGmHl0v5rjaCGZYhNO82J9C7SJ',
	},
});

export default apiCargaSaldoCencopay;
