import React from 'react';
import Papa from 'papaparse';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useAuth } from '../../../hooks/auth';

import apiCargaSaldoCencopay from '../../../services/apiCargaSaldoCencopay';
import apiCencopay from '../../../services/apiCencopay-gwUsersS3';

const LoadBalanceContext = React.createContext({});
LoadBalanceContext.displayName = 'Contexto de Agendamento de Carga de Saldo';

export function LoadBalanceProvider({ children }) {
	const history = useHistory();
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [description, setDescription] = React.useState('');
	const [approverEmail, setApproverEmail] = React.useState('');
	const [approverEmailList, setApproverEmailList] = React.useState([]);
	const [initialDate, setInitialDate] = React.useState('');
	const [finalDate, setFinalDate] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [openModal, setOpenModal] = React.useState(false);
	const [sources, setSources] = React.useState([]);
	const [selectedSource, setSelectedSource] = React.useState('');
	const [sapCostCenter, setSapCostCenter] = React.useState([]);
	const [selectedSapCostCenter, setSelectedSapCostCenter] = React.useState('');
	const [fileName, setFileName] = React.useState('');
	const [invalidBalanceData, setInvalidBalanceData] = React.useState([]);
	const [validBalanceData, setValidBalanceData] = React.useState([]);
	const [LoadBalanceDeatils, setLoadBalanceDetails] = React.useState([]);
	const [openModalBalanceDetails, setOpenModalBalanceDetails] =
		React.useState(false);
	const [selectedDeleteBalance, setSelectedDeleteBalance] = React.useState('');
	const [currentBalanceDetails, setCurrentBalanceDetails] = React.useState('');
	const [currentBalanceDescription, setCurrentBalanceDescription] =
		React.useState('');
	const [schedulingDetails, setSchedulingDetails] = React.useState([]);
	const [showSuccessMsg, setShowSuccessMsg] = React.useState(false);
	const [selectedDeleteBalanceScheduling, setSelectedDeleteBalanceScheduling] =
		React.useState('');
	const [openModalBalanceScheduling, setOpenModalBalanceScheduling] =
		React.useState(false);
	const [openModalInvalidCpf, setOpenModalInvalidCpf] = React.useState(false);
	const [maskedData, setMaskedData] = React.useState([]);
	const [monetaryClassificationList, setMonetaryClassificationList] =
		React.useState([]);
	const [selectedMonetaryClassification, setSelectedMonetaryClassification] =
		React.useState('');
	const [hasNegativeValue, setHasNegativeValue] = React.useState(false);
	const [openModalNegativeValue, setOpenModalNegativeValue] =
		React.useState(false);
	const [creditAccount, setCreditAccount] = React.useState('');
	const [debitAccount, setDebitAccount] = React.useState('');
	const [costCenter, setCostCenter] = React.useState('');
	const [openModalCostCenter, setOpenModalCostCenter] = React.useState(false);
	const [costCenterStore, setCostCenterStore] = React.useState('');
	const [costCenterDescription, setCostCenterDescription] = React.useState('');
	const [costCenterBrand, setCostCenterBrand] = React.useState('');
	const [costCenterCategoty, setCostCenterCategoty] = React.useState('');
	const [costCenterCreditAccount, setCostCenterCreditAccount] =
		React.useState('');
	const [costCenterDebitAccount, setCostCenterDebitAccount] =
		React.useState('');
	const [selectedCostCenterCategoty, setSelectedCostCenterCategoty] =
		React.useState('');
	const [selectedCostCenterCreditAccount, setSelectedCostCenterCreditAccount] =
		React.useState('');
	const [selectedCostCenterDebitAccount, setSelectedCostCenterDebitAccount] =
		React.useState('');

	const handleSwitchStatus = (status) => {
		switch (status) {
			case 'pending':
				return 'Pendente';
			case 'approved':
				return 'Aprovado';
			case 'canceled':
				return 'Cancelado';
			case 'processing':
				return 'Processando';
			case 'effective':
				return 'Efetivado';
			default:
				return status;
		}
	};

	const handleConsultScheduling = async () => {
		setIsLoading(true);

		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		await apiCargaSaldoCencopay
			.get(`/carga-saldo/agendamento`)
			.then((res) => {
				setData(res.data);
				handleInsertLog('agendamento carga de saldo', 'consulta', '');
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleConsultSchedulingById = async (id, passwd) => {
		setIsLoading(true);
		await apiCargaSaldoCencopay
			.get(`/carga-saldo/agendamento/${id}/${passwd}`)
			.then((res) => {
				setSchedulingDetails(res.data);
				handleInsertLog(
					'agendamento carga de saldo',
					'consulta',
					`Id agendamento: ${id}`
				);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleConsultLoadBalanceDetails = async (
		id,
		description,
		isnotApproveCunsult = true
	) => {
		setCurrentBalanceDetails(id);
		setCurrentBalanceDescription(description);
		setIsLoading(true);

		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});
		await apiCargaSaldoCencopay
			.get(`/carga-saldo/carga/${id}`)
			.then((res) => {
				setLoadBalanceDetails(res.data);
				handleInsertLog(
					'agendamento carga de saldo',
					'consulta',
					`Id agendamento: ${id}`
				);
				onChangeLoading({ open: false });
				setIsLoading(false);

				setMaskedData(
					res.data?.map((item) => {
						const cpf = item.cpf;
						const maskedCpf = `${cpf.slice(0, 3)}.***.***-${cpf.slice(-2)}`;

						return {
							...item,
							cpf: maskedCpf,
							status: handleSwitchStatus(item?.status),
							date_start: moment(item?.date_start).format('DD/MM/YYYY'),
							date_finish: moment(item?.date_finish).format('DD/MM/YYYY'),
						};
					})
				);

				// setMaskedData();

				isnotApproveCunsult && history.push('/cencopay/carga-saldo-detalhes');
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleConsultApproverEmail = async (id) => {
		setCurrentBalanceDetails(id);
		setIsLoading(true);

		await apiCargaSaldoCencopay
			.get(`/carga-saldo/email-aprovador`)
			.then((res) => {
				setApproverEmailList(res.data);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleConsultMonetaryClassification = async (id) => {
		setCurrentBalanceDetails(id);
		setIsLoading(true);
		await apiCargaSaldoCencopay
			.get(`/carga-saldo/class-monetaria`)
			.then((res) => {
				setMonetaryClassificationList(res.data);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleConsultSources = async () => {
		setIsLoading(true);
		await apiCencopay
			.get(`/sources`)
			.then((res) => {
				setSources(res.data);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleUpdateSchedulingStatus = async (
		id_scheduling,
		passwd,
		status
	) => {
		setIsLoading(true);

		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		await apiCargaSaldoCencopay
			.put(`/carga-saldo/agendamento/${id_scheduling}/${passwd}/${status}`)
			.then((res) => {
				if (res.status === 202) {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: res.data,
					});
				} else if (res.status === 200) {
					handleInsertLog(
						'agendamento carga de saldo',
						`update staus`,
						`Status: ${status} - Id Agendamento: ${id_scheduling}`
					);
					onChangeLoading({ open: false });
					setShowSuccessMsg(true);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleConsultSapCostCenter = async () => {
		setIsLoading(true);
		await apiCencopay
			.get(`/costcenter`)
			.then((res) => {
				setSapCostCenter(res.data);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleCreateCostCenter = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Cadastrando...',
		});

		const payload = {
			store: costCenterStore,
			costCenter: costCenterDescription,
			brand: costCenterBrand,
			creditAccount: '',
			debitAccount: '',
			categoty: '',
		};
		await apiCencopay
			.post(`/costcenter`, payload)
			.then((res) => {
				onChangeLoading({ open: false });
				setIsLoading(false);
				setCostCenterStore('');
				setCostCenterDescription('');
				setCostCenterBrand('');
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Cadastrado com sucesso!',
				});
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleInsertBalanceScheduling = async () => {
		const payload = {
			description: description,
			date_start: initialDate,
			date_finish: finalDate,
			approver_email: approverEmail,
			source_id: selectedSource,
			cost_center_sap_id: selectedSapCostCenter,
			balance: validBalanceData,
			typeMonetary: selectedMonetaryClassification,
			creator_email: user?.email,
		};

		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		await apiCargaSaldoCencopay
			.post(`/carga-saldo/agendamento`, payload)
			.then((res) => {
				setData(res.status === 201);
				handleInsertLog(
					'agendamento carga de saldo',
					'cadastro',
					`{
						scheduling_id: ${res?.data?.scheduling_id},
						description: ${description},
						date_start: ${initialDate},
						date_finish: ${finalDate},
						approver_email: ${approverEmail},
						source_id: ${selectedSource},
						cost_center_sap_id: ${selectedSapCostCenter}
					};
				`
				);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Cadastrado com sucesso!',
				});
				handleCleanInputs();
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleDeleteBalanceCharge = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		await apiCargaSaldoCencopay
			.delete(`/carga-saldo/carga/${selectedDeleteBalance}`)
			.then((res) => {
				if (res.status === 200) {
					handleInsertLog(
						'agendamento carga de saldo',
						'exlusao',
						`Id carga: ${selectedDeleteBalance}`
					);
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'success',
						msg: 'Excluído com sucesso!',
					});
				}
				handleConsultLoadBalanceDetails(
					currentBalanceDetails,
					currentBalanceDescription
				);
				setIsLoading(false);
				handleCloseModalBalanceDetails();
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleDeleteBalanceChargeScheduling = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		await apiCargaSaldoCencopay
			.delete(`/carga-saldo/agendamento/${selectedDeleteBalanceScheduling}`)
			.then((res) => {
				if (res.status === 200) {
					handleInsertLog(
						'agendamento carga de saldo',
						'exclusao',
						`Id agendamento: ${selectedDeleteBalanceScheduling}`
					);
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'success',
						msg: 'Excluído com sucesso!',
					});
					handleCloseModalDeleteScheduling();
				}
				handleConsultScheduling();
				setIsLoading(false);
				handleCloseModalBalanceDetails();
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleCleanInputs = () => {
		setDescription('');
		setApproverEmail('');
		setInitialDate('');
		setFinalDate('');
		setSelectedSapCostCenter('');
		setSelectedSource('');
		setFileName('');
		setInvalidBalanceData([]);
		setValidBalanceData([]);
		setSelectedMonetaryClassification('');
		setCreditAccount('');
		setDebitAccount('');
		setCostCenterStore('');
		setCostCenterDescription('');
		setCostCenterBrand('');
		setSelectedCostCenterDebitAccount('');
		setSelectedCostCenterCreditAccount('');
		setSelectedCostCenterCategoty('');
		setCostCenterDebitAccount('');
		setCostCenterCreditAccount('');
		setCostCenterCategoty('');
		setCostCenterStore('');
		setCostCenterDescription('');
		setCostCenterBrand('');
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setHasNegativeValue(false);
		setOpenModal(false);
		handleCleanInputs();
		handleConsultScheduling();
		setFileName('');
		setValidBalanceData([]);
		setInvalidBalanceData([]);
	};

	const validarCPF = (cpf) => {
		// Remove todos os caracteres que não são dígitos
		cpf = cpf.replace(/[^\d]+/g, '');

		// Verifica se o CPF tem 11 dígitos
		if (cpf.length !== 11) {
			return false;
		}

		// Verifica se todos os dígitos são iguais, o que não é um CPF válido
		if (/^(\d)\1+$/.test(cpf)) {
			return false;
		}

		// Validação do primeiro dígito verificador
		let soma = 0;
		for (let i = 0; i < 9; i++) {
			soma += parseInt(cpf.charAt(i)) * (10 - i);
		}
		let resto = 11 - (soma % 11);
		let digitoVerificador1 = resto >= 10 ? 0 : resto;

		// Verificação do primeiro dígito
		if (digitoVerificador1 !== parseInt(cpf.charAt(9))) {
			return false;
		}

		// Validação do segundo dígito verificador
		soma = 0;
		for (let i = 0; i < 10; i++) {
			soma += parseInt(cpf.charAt(i)) * (11 - i);
		}
		resto = 11 - (soma % 11);
		let digitoVerificador2 = resto >= 10 ? 0 : resto;

		// Verificação do segundo dígito
		if (digitoVerificador2 !== parseInt(cpf.charAt(10))) {
			return false;
		}

		return true;
	};

	const handleOpenModalInvalidCpf = () => {
		setOpenModalInvalidCpf(true);
	};

	const handleCloseModalInvalidCpf = () => {
		setOpenModalInvalidCpf(false);
	};

	const handleDocument = (event) => {
		setFileName('');
		setValidBalanceData([]);
		setInvalidBalanceData([]);
		setHasNegativeValue(false);

		Papa.parse(event.target.files[0], {
			header: true,
			dynamicTyping: true,
			skipEmptyLines: 'greedy', // ignora linhas vazias ou apenas com espaços após conversão
			complete: (results) => {
				const balance = results.data;
				balance.forEach((item) => {
					item.cpf = String(item.cpf).padStart(11, '0');
				});

				setValidBalanceData(
					balance?.filter((item) =>
						validarCPF(String(item.cpf).padStart(11, '0'))
					)
				);

				setInvalidBalanceData(
					balance?.filter(
						(item) => !validarCPF(String(item.cpf).padStart(11, '0'))
					)
				);

				setHasNegativeValue(balance.some((item) => item?.amount < 0));
				setOpenModalNegativeValue(balance.some((item) => item?.amount < 0));

				setFileName(event.target.files[0].name);
			},
		});
	};

	const handleReturnToPreviewPage = () => {
		setCurrentBalanceDetails('');
		history.goBack();
	};

	const handleOpenModalBalanceDetails = (id) => {
		setSelectedDeleteBalance(id);
		setOpenModalBalanceDetails(true);
	};

	const handleCloseModalBalanceDetails = () => {
		setSelectedDeleteBalance('');
		setOpenModalBalanceDetails(false);
	};

	const handleOpenModalDeleteScheduling = (id) => {
		setSelectedDeleteBalanceScheduling(id);
		setOpenModalBalanceScheduling(true);
	};

	const handleCloseModalDeleteScheduling = () => {
		setSelectedDeleteBalanceScheduling('');
		setOpenModalBalanceScheduling(false);
	};

	const handleInsertLog = async (screen, action, additionalInfo) => {
		const payload = {
			UserAD: user?.login_ad,
			NameAD: user?.name,
			RegistrationAD: user?.matricula,
			Screen: screen,
			Action: action,
			AdditionalInfo: additionalInfo,
		};

		setIsLoading(true);
		await apiCargaSaldoCencopay
			.post(`/log`, payload)
			.then((res) => {})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleCloseModalNegativeValue = () => {
		setOpenModalNegativeValue(false);
	};

	const handleOpenModalNegativeValue = () => {
		setOpenModalNegativeValue(true);
	};

	const handleCloseModalCostCenter = () => {
		setOpenModalCostCenter(false);
		setCostCenterStore('');
		setCostCenterDescription('');
		setCostCenterBrand('');
		handleCleanInputs();
	};

	const handleOpenModalCostCenter = () => {
		setOpenModalCostCenter(true);
	};

	return (
		<LoadBalanceContext.Provider
			value={{
				description,
				setDescription,
				approverEmail,
				setApproverEmail,
				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,
				isLoading,
				setIsLoading,
				data,
				openModal,
				setOpenModal,
				handleOpenModal,
				handleCloseModal,
				handleConsultScheduling,
				handleConsultSources,
				sources,
				setSources,
				handleConsultSapCostCenter,
				sapCostCenter,
				setSapCostCenter,
				selectedSapCostCenter,
				setSelectedSapCostCenter,
				selectedSource,
				setSelectedSource,
				fileName,
				handleDocument,
				invalidBalanceData,
				setInvalidBalanceData,
				validBalanceData,
				setValidBalanceData,
				handleInsertBalanceScheduling,
				LoadBalanceDeatils,
				handleConsultLoadBalanceDetails,
				handleReturnToPreviewPage,
				openModalBalanceDetails,
				handleOpenModalBalanceDetails,
				handleCloseModalBalanceDetails,
				handleDeleteBalanceCharge,
				schedulingDetails,
				handleConsultSchedulingById,
				setShowSuccessMsg,
				handleUpdateSchedulingStatus,
				showSuccessMsg,
				setSelectedDeleteBalanceScheduling,
				handleDeleteBalanceChargeScheduling,
				openModalBalanceScheduling,
				handleOpenModalDeleteScheduling,
				handleCloseModalDeleteScheduling,
				approverEmailList,
				handleConsultApproverEmail,
				openModalInvalidCpf,
				handleOpenModalInvalidCpf,
				handleCloseModalInvalidCpf,
				maskedData,
				monetaryClassificationList,
				selectedMonetaryClassification,
				setSelectedMonetaryClassification,
				handleConsultMonetaryClassification,
				hasNegativeValue,
				openModalNegativeValue,
				handleCloseModalNegativeValue,
				handleOpenModalNegativeValue,
				currentBalanceDescription,
				handleSwitchStatus,
				creditAccount,
				setCreditAccount,
				debitAccount,
				setDebitAccount,
				costCenter,
				setCostCenter,
				openModalCostCenter,
				handleCloseModalCostCenter,
				handleOpenModalCostCenter,
				costCenterStore,
				setCostCenterStore,
				costCenterDescription,
				setCostCenterDescription,
				costCenterBrand,
				setCostCenterBrand,
				handleCreateCostCenter,
				costCenterCategoty,
				setCostCenterCategoty,
				costCenterCreditAccount,
				setCostCenterCreditAccount,
				costCenterDebitAccount,
				setCostCenterDebitAccount,
				selectedCostCenterCategoty,
				setSelectedCostCenterCategoty,
				selectedCostCenterCreditAccount,
				setSelectedCostCenterCreditAccount,
				selectedCostCenterDebitAccount,
				setSelectedCostCenterDebitAccount,
			}}
		>
			{children}
		</LoadBalanceContext.Provider>
	);
}

export function useLoadBalance() {
	const context = React.useContext(LoadBalanceContext);

	if (!context)
		throw new Error('useLoadBalance must be used within an AuthProvider');

	return context;
}
