import React, { useEffect } from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiCencopay from '../../../services/apiCencopay-gwUsersS3';
import apiCupomFiscal from '../../../services/apiCupomFiscal';

const CashbackContext = React.createContext({
	onChangeCashbackClient: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

CashbackContext.displayName = 'Contexto de Cashback - Cencopay';

export function CashbackProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [cpf, setCpf] = React.useState('');
	const [data, setData] = React.useState([]);
	// const [dataTicket, setDataTicket] = React.useState([]);

	const itemsPerPage = 5;

	const totalpages = 0;

	const handleNext = () => {
		setActiveStep((prevActiveStep) =>
			prevActiveStep === 0 ? 1 : prevActiveStep
		);
	};

	const handleBack = () => {
		setActiveStep(0);
	};

	const handleConsult = async (tipo, page) => {
		setIsLoading(true);
		let url = '';
		let errorMessage = '';

		switch (tipo) {
			case 1:
				url = `/users/sisf/${cpf
					.replace('.', '')
					.replace('.', '')
					.replace('-', '')}`;
				errorMessage = 'Cliente não encontrado!';
				break;
			case 2:
				url = `/Wallet/${cpf
					.replace('.', '')
					.replace('.', '')
					.replace('-', '')}/${page}/${itemsPerPage}`;
				errorMessage = 'Extratos não encontrados!';
				break;
			case 3:
				url = `/CashInCampaign/${cpf
					.replace('.', '')
					.replace('.', '')
					.replace('-', '')}/${page}/${itemsPerPage}`;
				errorMessage = 'Pendências não encontradas!';
				break;
			default:
				setIsLoading(false);
				return;
		}

		try {
			const options = {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			};
			const res = await apiCencopay.get(url, options);
			setIsLoading(false);
			onChangeLoading({ open: false });

			let isValidData = false;

			if (Array.isArray(res.data)) {
				if (res.data.length > 0) {
					isValidData = true;
				} else {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: errorMessage,
					});
				}
			} else if (typeof res.data === 'object' && res.data !== null) {
				if (res.data.onboarding === null) {
					isValidData = true;
				} else {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: errorMessage,
					});
				}
			} else {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: errorMessage,
				});
			}

			if (isValidData) {
				handleNext();
				setData(res.data);
				setActiveStep(tipo);
				onChangeNotify({ open: false });
			}
		} catch (error) {
			setIsLoading(false);
			onChangeLoading({ open: false });
			onChangeNotify({
				open: true,
				class: 'error',
				msg: `Aconteceu algum erro na busca: ${errorMessage}, tente novamente!`,
			});
		}
	};

	const handleGetTicket = async (loja, pdv, cupom, dataFiscal) => {
		setIsLoading(true);
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		try {
			const response = await apiCupomFiscal.post('/ConsultaCupomFiscal', {
				loja,
				pdv,
				cupom,
				dataFiscal,
			});

			setIsLoading(false);
			onChangeLoading({
				open: false,
				msg: '',
			});
			if (response.data.status === 'cupom nao encontrado') {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Cupom não encontrado, tente novamente!',
				});
				return null;
			}
			return response.data;
		} catch (error) {
			setIsLoading(false);
			onChangeLoading({
				open: false,
				msg: '',
			});
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Aconteceu algum erro na busca, tente novamente!',
			});
			return null;
		}
	};

	return (
		<CashbackContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				setActiveStep,
				handleConsult,
				isLoading,
				setIsLoading,
				cpf,
				setCpf,
				data,
				itemsPerPage,
				totalpages,
				handleGetTicket,
			}}
		>
			{children}
		</CashbackContext.Provider>
	);
}

export function useCashback() {
	const context = React.useContext(CashbackContext);

	if (!context)
		throw new Error('useCashbackClient must be used within an AuthProvider');

	return context;
}
