import React from 'react';
import Papa from 'papaparse';
import { useHistory } from 'react-router-dom';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useAuth } from '../../../hooks/auth';

import apiCampanhaCencopay from '../../../services/apiCampanhaCencopay';

const RegisterCampaignContext = React.createContext({});
RegisterCampaignContext.displayName =
	'Contexto de Cadastro de Campanha - Cencopay';

export function RegisterCampaignProvider({ children }) {
	const history = useHistory();
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState();
	const [activeStep, setActiveStep] = React.useState(0);
	const [initialDate, setInitialDate] = React.useState('');
	const [finalDate, setFinalDate] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [status, setStatus] = React.useState(false);
	const [associatedStore, setAssociatedStore] = React.useState('all');
	const [campaignType, setCampaignType] = React.useState('itemType');
	const [finishers, setFinishers] = React.useState([]);
	const [selectedFinishers, setSelectedFinishers] = React.useState([]);
	const [balanceMaxValue, setBalanceMaxValue] = React.useState('');
	const [cashbackPercent, setCashbackPercent] = React.useState('');
	const [validItems, setValidItems] = React.useState([]);
	const [invalidItems, setInvalidItems] = React.useState([]);
	const [itemsToValidete, setItemsToValidete] = React.useState([]);
	const [isEdit, setIsEdit] = React.useState(false);
	const [viewItemsOnEdit, setViewItemsOnEdit] = React.useState([]);
	const [openModalInvalidItems, setOpenModalInvalidItems] =
		React.useState(false);
	const [stores, setStores] = React.useState([]);
	const [selectedIdToEdit, setSelectedIdToEdit] = React.useState('');
	const [uptadeItemDescription, setUpdateItemDescription] = React.useState('');
	const [updateItemProductCode, setUpdateItemProductCode] = React.useState('');
	const [updateItemStatus, setUpdateItemStatus] = React.useState(false);
	const [enabledId, setEnabledId] = React.useState(null);
	const [storeShowcaseData, setStoreShowcaseData] = React.useState([]);

	const handleBack = () => {
		setActiveStep(0);
	};

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleRegisterStep = () => {
		setIsEdit(false);
		history.push('/cencopay/etapa-cadastro-campanha');
	};

	const handleCleanInputs = () => {
		setData([]);
		setInitialDate('');
		setFinalDate('');
		setDescription('');
		setStatus(false);
		setAssociatedStore('all');
		setCampaignType('itemType');
		setSelectedFinishers([]);
		setBalanceMaxValue('');
		setCashbackPercent('');
		setValidItems([]);
		setInvalidItems([]);
		setItemsToValidete([]);
		setIsEdit(false);
		setViewItemsOnEdit([]);
		setOpenModalInvalidItems(false);
		setSelectedIdToEdit('');
		setUpdateItemProductCode('');
		setUpdateItemStatus(false);
		setEnabledId(null);
		setStoreShowcaseData([]);
	};

	const handleReturnToIndex = () => {
		setData([]);
		history.goBack();
	};

	const handleDocument = (acceptedFiles) => {
		const file = acceptedFiles[0];
		setItemsToValidete([]);

		Papa.parse(file, {
			header: true,
			dynamicTyping: true,
			skipEmptyLines: 'greedy', // ignora linhas vazias ou apenas com espaços após conversão
			complete: (results) => {
				setItemsToValidete(results.data);
				handleValidateItems(results.data);
			},
		});
	};

	const handleConsultCampaign = async () => {
		onChangeLoading({ open: false });

		setIsLoading(true);
		await apiCampanhaCencopay
			.get(`/Campaign`)
			.then((res) => {
				setData(res.data);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleValidateItems = async (payload) => {
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Carregando CSV...',
		});

		setIsLoading(true);
		await apiCampanhaCencopay
			.post(`/Campaign/import-json`, payload)
			.then((res) => {
				setValidItems(res?.data?.itensOk);
				setInvalidItems(res?.data?.itensNok);
				setOpenModalInvalidItems(res?.data?.itensNok?.length > 0);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleConsultFinishers = async () => {
		setIsLoading(true);
		await apiCampanhaCencopay
			.get(`/Finalizadoras`)
			.then((res) => {
				setFinishers(res.data);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleConsultStores = async () => {
		setIsLoading(true);
		await apiCampanhaCencopay
			.get(`/Stores`)
			.then((res) => {
				setStores(res.data);
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const transformPaymentData = (data, cashBackValue, maxValue) => {
		if (data.length === 0) return [];

		return data.map((item) => ({
			cashBackValue: cashBackValue,
			maxValue: maxValue,
			finalizadoraDescription: item.description,
			finalizadoraId: item.id_finalizadora,
		}));
	};

	const handleInsertCampaign = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Cadastrando...',
		});

		const payload = {
			id: 0,
			startDate: initialDate,
			endDate: finalDate,
			description: description,
			active: status,
			stores: stores.map((store) => ({ storeId: store.id })),
			allStores: true,
			items: validItems,
			categoryGroup: {},
			paymentGroup: transformPaymentData(
				selectedFinishers,
				cashbackPercent,
				balanceMaxValue
			),
		};

		await apiCampanhaCencopay
			.post(`/Campaign`, payload)
			.then((res) => {
				onChangeLoading({ open: false });
				setIsLoading(false);
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Campanha cadastrada com sucesso!',
				});
				setData([]);
				handleViewStoreShowcase({ id: res?.data?.id });
				// history.replace('/cencopay/campanha');
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				if (error.response.status === 504) {
					onChangeNotify({
						open: true,
						class: 'success',
						msg: 'Campanha em processo de cadastro. Ela estará disponível em até 5 minutos.',
					});
					setData([]);
					history.replace('/cencopay/campanha');
				} else {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				}
			});
	};

	const updateUrlImage = (idItem, newCode, newDescription, newStatus) => {
		storeShowcaseData?.forEach((categoria) => {
			categoria.itens.forEach(async (item) => {
				if (item.idItem === idItem) {
					item.urlImage = await item.urlImage.replace(
						/\/300\/\d+\.png$/,
						`/300/${newCode}.png`
					);
					item.descricao = newDescription;
					item.flShowcase = newStatus;
				}
			});
		});
	};

	const getImageCode = (url) => {
		const regex = /https:\/\/d19xaqgeerwnp\.cloudfront\.net\/300\/(.*)\.png/;
		const match = url.match(regex);
		return match ? match[1] : null;
	};

	const handleUpdateItem = async (item, campaignId) => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Atualizando item...',
		});

		const payload = {
			sku: item?.productSku,
			campaignId: campaignId,
			descricaoItem: uptadeItemDescription,
			cdImagem: updateItemProductCode.toString(),
			flShowcase: updateItemStatus,
		};

		await apiCampanhaCencopay
			.put(`/Campaign/uptade-item`, payload)
			.then((res) => {
				onChangeLoading({ open: false });

				updateUrlImage(
					item?.idItem,
					updateItemProductCode,
					uptadeItemDescription,
					updateItemStatus
				);

				setEnabledId(null);
				setUpdateItemDescription('');
				setUpdateItemProductCode('');
				setUpdateItemStatus(false);

				setIsLoading(false);
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Item atualizado com sucesso!',
				});
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleViewStoreShowcase = async (items) => {
		console.log(items);
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Consultando vitrine...',
		});
		await apiCampanhaCencopay
			.get(`/Showcase/vitrineSisf/${items?.id}`)
			.then((res) => {
				setStoreShowcaseData(res.data);
				onChangeLoading({ open: false });
				setIsLoading(false);

				history.push('/cencopay/vitrine', {
					campaignId: items?.id,
				});
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const transformData = (data) => {
		if (data.length === 0) return [];

		setBalanceMaxValue(data[0]?.maxValue);
		setCashbackPercent(data[0]?.cashBackValue);

		return data.map((item) => ({
			description: item.finalizadoraDescription,
			id_finalizadora: item.finalizadoraId,
		}));
	};

	const handleUpdateCampaign = async () => {
		setIsLoading(true);

		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Atualizando...',
		});
		const payload = {
			id: selectedIdToEdit,
			startDate: initialDate,
			endDate: finalDate,
			description: description,
			active: status,
			stores: stores.map((store) => ({ storeId: store.id })),
			allStores: true,
			items: validItems,
			categoryGroup: {},
			paymentGroup: transformPaymentData(
				selectedFinishers,
				cashbackPercent,
				balanceMaxValue
			),
		};

		await apiCampanhaCencopay
			.put(`/Campaign/${selectedIdToEdit}`, payload)
			.then((res) => {
				onChangeLoading({ open: false });
				setIsLoading(false);
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Campanha atualizada com sucesso!',
				});
				history.replace('/cencopay/campanha');
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleUpdateStatus = async () => {
		setIsLoading(true);
		onChangeLoading({ open: true });
		onChangeLoading({
			open: true,
			msg: 'Atualizando status...',
		});

		await apiCampanhaCencopay
			.put(`/Campaign/ActiveOrInactive/${selectedIdToEdit}`)
			.then((res) => {
				setStatus(res?.data?.active);
				onChangeLoading({ open: false });
				setIsLoading(false);
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Status atualizado!',
				});
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleEditStep = (data) => {
		setIsEdit(true);
		setSelectedIdToEdit(data?.id);
		setDescription(data?.description);
		setInitialDate(data?.startDate);
		setFinalDate(data?.endDate);
		setStatus(data?.active);
		setCampaignType(data?.items?.length > 0 ? 'itemType' : 'paymentType');
		setViewItemsOnEdit(data?.items);
		setSelectedFinishers(transformData(data?.paymentGroup));
		history.push({
			pathname: '/cencopay/etapa-cadastro-campanha',
			state: data,
		});
	};

	const handlOpenModalInvalidItems = () => {
		setOpenModalInvalidItems(true);
	};

	const handlCloseModalInvalidItems = () => {
		setOpenModalInvalidItems(false);
	};

	const handleGoBackStoreShowcase = () => {
		setData([]);
		history.replace('/cencopay/campanha');
		// history.goBack();
	};

	return (
		<RegisterCampaignContext.Provider
			value={{
				data,
				isLoading,
				activeStep,
				handleBack,
				handleNext,
				handleRegisterStep,
				handleReturnToIndex,
				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,
				description,
				setDescription,
				status,
				setStatus,
				associatedStore,
				setAssociatedStore,
				campaignType,
				setCampaignType,
				finishers,
				selectedFinishers,
				setSelectedFinishers,
				balanceMaxValue,
				setBalanceMaxValue,
				cashbackPercent,
				setCashbackPercent,
				validItems,
				setValidItems,
				invalidItems,
				setInvalidItems,
				handleDocument,
				handleConsultCampaign,
				handleConsultFinishers,
				isEdit,
				handleEditStep,
				viewItemsOnEdit,
				openModalInvalidItems,
				handlOpenModalInvalidItems,
				handlCloseModalInvalidItems,
				itemsToValidete,
				handleInsertCampaign,
				stores,
				handleConsultStores,
				handleUpdateCampaign,
				handleUpdateStatus,
				handleViewStoreShowcase,
				handleGoBackStoreShowcase,
				handleUpdateItem,
				uptadeItemDescription,
				setUpdateItemDescription,
				updateItemProductCode,
				setUpdateItemProductCode,
				enabledId,
				setEnabledId,
				storeShowcaseData,
				handleCleanInputs,
				getImageCode,
				updateItemStatus,
				setUpdateItemStatus,
			}}
		>
			{children}
		</RegisterCampaignContext.Provider>
	);
}

export function useRegisterCampaign() {
	const context = React.useContext(RegisterCampaignContext);

	if (!context)
		throw new Error('useRegisterCampaign must be used within an AuthProvider');

	return context;
}
