import React, { createContext, useContext, useState, useEffect } from 'react';
import apiProjects from '../../../services/apiProjects'; // Importa o serviço da API

const ProjectsManagerContext = createContext();

export function ProjectsManagerProvider({ children }) {
  const [dataManager, setDataManager] = useState([]);
  const [dataUpdate, setDataUpdate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Método para buscar dados da API
  const fetchData = async () => {
    setLoading(true);
    setError(null); // Reseta o erro antes da nova busca
    try {
      const response = await apiProjects.get('/projects'); // Ajuste a URL conforme necessário
      setDataManager(response.data); // Atualiza o estado com os dados recebidos
    } catch (err) {
      setError(`Erro ao buscar dados: ${  err.message}`); // Define a mensagem de erro
    } finally {
      setLoading(false); // Finaliza o loading
    }
  };

  const updateData = async (data) => {
    setLoading(true);
    setError(null);
    console.log(data)
   try {
     const response = await apiProjects.put(`/projects/${data.id}`, data)
     setDataUpdate(response)
   } catch (err) {
    setError(`Erro ao buscar dados: ${  err.message}`);
   } finally {
     setLoading(false)
   }
  }

  // Efeito para buscar dados ao montar o componente
  useEffect(() => {
    fetchData();
  }, [dataUpdate]);

  return (
    <ProjectsManagerContext.Provider value={{
      dataManager,
      loading,
      error,
      updateData,
    }}>
      {children}
    </ProjectsManagerContext.Provider>
  );
}

// Hook para usar o contexto
export const useProjectsManager = () => useContext(ProjectsManagerContext);
